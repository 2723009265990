import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from '../Checkmark';
import classNames from 'classnames';
import './styles.scss';

const TextInput = ({
  modifier,
  type,
  readOnly,
  label,
  hideLabel,
  name,
  value,
  placeholder,
  error,
  approved,
  onChange,
  min,
  max,
  maxLength,
  numberOnly,
  telOnly,
  className,
  pin,
  containerClass,
  ...props
}) => {
  const validateInput = (e) => {
    const { value } = e.target;

    if (numberOnly && !/^\d*$/.test(value)) {
      return;
    } else if (telOnly && !/^\+$|^\+4$|^\+45\d{0,8}$|^\d{0,8}$/.test(value)) {
      return;
    }

    onChange(name, value);
  };

  const inputIndicator =
    (error && <span className="input-indicator error">!</span>) || null;

  const inputClass = classNames(
    'component-textinput__label__input',
    error && 'component-textinput__label__input--error',
    pin && 'component-textinput__label__input--pin',
    className
  );

  const containerClasses = classNames(
    'component-textinput',
    `component-textinput-${type}`,
    error && 'has-validation-error',
    modifier && `component-textinput--${modifier}`,
    containerClass
  );

  const labelClasses = classNames(
    'component-textinput__label__text',
    hideLabel && 'ys-u-visuallyhidden'
  );

  return (
    <div className={containerClasses}>
      <label className="component-textinput__label">
        {label && <span className={labelClasses}>{label}</span>}
        <input
          className={inputClass}
          name={name}
          type={type}
          value={value}
          onChange={validateInput}
          readOnly={readOnly}
          placeholder={placeholder}
          min={min}
          max={max}
          maxLength={maxLength}
          noValidate
          autoComplete="off"
          {...props}
        />
        {inputIndicator ||
          (approved && (
            <Checkmark className="component-textinput__label__approved"></Checkmark>
          ))}
        {pin && (
          <div className="component-textinput__label__pin-border">
            <span />
            <span />
            <span />
            <span />
          </div>
        )}
        {error && (
          <div className="component-textinput__label__error-text">{error}</div>
        )}
      </label>
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  hideLabel: PropTypes.bool,
  modifier: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  maxLength: PropTypes.string,
  className: PropTypes.string,
  numberOnly: PropTypes.bool,
  telOnly: PropTypes.bool,
  pin: PropTypes.bool,
  approved: PropTypes.bool,
  readOnly: PropTypes.bool,
};

TextInput.defaultProps = {
  type: 'text',
  autoFocus: false,
};

export default TextInput;
