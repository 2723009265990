import React, { useState, useRef } from 'react';
import Button from '../../modules/Button/';
import NumberConfig from './NumberConfig';
import './style.scss';
import { finalizeDialog } from '../../api/endpoints';
import basketIcon from './basket.svg';

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
};

const Order = (props) => {
  const {
    basket,
    setIsCompleted,
    productBasket,
    email,
    existingType,
    setProductBasket,
    isStandAlone,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const ordersRef = useRef(null);

  const [contactOptionSms, setContactOptionSms] = useState(false);
  const [contactOptionEmail, setContactOptionEmail] = useState(false);
  //const [configs, setConfigs] = useState([]);
  const [activeConfig, setActiveConfig] = useState(0);

  const endDialog = async () => {
    const response = await finalizeDialog(basket.productBasketIds[0]);

    try {
      const basket = await response.json();
      console.log(basket);
    } catch (error) {
      console.log(error);
    }
  };

  const finishOrder = async () => {
    setIsLoading(true);
    await endDialog();
    setIsCompleted();
    setIsLoading(false);
  };

  const subscriptions =
    productBasket?.customerSubscriptions?.subscriptions || null;

  const changedSubscriptions = subscriptions.filter((sub) => !sub.locked);

  let priceRecurring = 0;
  let priceCreation = 0;

  try {
    changedSubscriptions.forEach((subscription) => {
      priceRecurring += subscription.priceSpecification.unitPricePrMonth;
    });
  } catch {
    priceRecurring = 0;
  }

  try {
    changedSubscriptions.forEach((subscription) => {
      priceCreation += subscription.priceSpecification.priceline.find(
        (line) => line.type === 'CREATION'
      ).actualPrice;
    });
  } catch {
    priceCreation = 0;
  }

  return (
    <div className="order">
      <section className="order__basket">
        <div className="order__basket__left">
          <img
            className="order__basket__left__icon"
            alt="Kurv"
            src={basketIcon}
          />
          <div>
            <p>Betal pr. måned</p>
            <p>Engangsbetaling</p>
          </div>
        </div>
        <div className="order__basket__right">
          <p>{priceRecurring},-</p>
          <p>{priceCreation},-</p>
        </div>
      </section>
      <section className="order__details" ref={ordersRef}>
        <h2 className="order__details__title">Bestilling</h2>
        {changedSubscriptions?.length > 1 && (
          <section
            className="ys-flow-step shared-card__steps ys-col-lg-8 ys-offset-lg-2 ys-col-12"
            role="progressbar"
            aria-valuemin={0}
            aria-valuetext="Subscriptions configuration"
            aria-valuemax={changedSubscriptions?.length || 0}
          >
            <ol className="ys-flow-steps__list" aria-hidden="true">
              {changedSubscriptions.map((subscription, index) => {
                const key = `step-${index}`;

                return (
                  <li
                    className={`ys-flow-steps__item shared-card__steps__step ${
                      index === activeConfig &&
                      'ys-flow-steps__item--active shared-card__steps__step--active'
                    }`}
                    key={key}
                  >
                    <span className="ys-flow-steps__label">
                      Abonnement {index + 1}
                    </span>
                  </li>
                );
              })}
            </ol>
          </section>
        )}
        {changedSubscriptions &&
          changedSubscriptions.map((subscription, index) => {
            const key = `order-subscriptions-${index}`;
            let selectedNumber = '0';
            let numberTypeText = 'Behold dit nummer - ';

            try {
              selectedNumber = Object.keys(
                productBasket.customerSubscriptions.subscriptionIndexMap
              ).find(
                (key) =>
                  productBasket.customerSubscriptions.subscriptionIndexMap[
                    key
                  ] === subscription.subscriptionIndex
              );
            } catch (error) {
              //Do nothing
            }

            try {
              const addons = Object.entries(subscription.addons);

              const numberSettings = addons.filter((addon) => {
                return addon[1].groupClass === 'NUMBER_TYPE';
              });

              const numberType = numberSettings.filter((addon) => {
                return addon[1].selected;
              });

              if (numberType[0][1].name === 'Mobilnummer') {
                numberTypeText = 'Nyt nummer - ';
              }

              if (numberType[0][1].name === 'Nummerportering') {
                numberTypeText = 'Flyt nummer - ';
              }
            } catch (error) {
              //Do nothing
            }

            const shouldChange =
              !existingType ||
              !!(
                existingType === types.EXISTING_ADD_EXTRA &&
                !subscription.subscriptionNumber
              );

            if (!shouldChange) {
              numberTypeText = 'Gentegn - ';
            }

            if (activeConfig === index) {
              return (
                <NumberConfig
                  key={key}
                  email={email}
                  existingType={existingType}
                  basket={basket}
                  setIsCompleted={setIsCompleted}
                  setProductBasket={setProductBasket}
                  subscription={subscription}
                  isStandAlone={isStandAlone}
                  preSelectedNumber={selectedNumber}
                  onContinue={() => {
                    //Because I don't feel like checking browsers in js, we'll just TRY to scroll into view, and if it doesn't work it just doesn't work
                    try {
                      ordersRef?.current?.scrollIntoView({});
                    } catch (error) {
                      console.log(error);
                    }

                    setActiveConfig((index += 1));
                  }}
                  lastOne={index === subscriptions.length - 1}
                />
              );
            } else {
              return (
                <section className="order__configured">
                  <h1 className="order__configured__title">
                    {subscription.productName}
                  </h1>
                  {selectedNumber && selectedNumber !== '0' && (
                    <h2 className="order__configured__number">
                      {numberTypeText}
                      {selectedNumber.match(/.{1,2}/g).join(' ')}
                    </h2>
                  )}
                  <Button
                    addClass="order__configured__edit"
                    isLink
                    value="Rediger"
                    onClick={() => setActiveConfig(index)}
                  />
                </section>
              );
            }
          })}
        <hr />
        {activeConfig >= changedSubscriptions.length && (
          <div>
            <section className="order__section">
              <h1 className="order__section__title">
                Tilladelse til markedsføring
              </h1>
              <div className="ys-checkbox">
                <input
                  type="checkbox"
                  name="contactOption"
                  id="6"
                  value="sms"
                  checked={contactOptionSms}
                  onChange={() =>
                    setContactOptionSms((prevState) => !prevState)
                  }
                  className="ys-checkbox__control ys-u-visuallyhidden"
                />
                <label htmlFor="6" className="ys-checkbox__label">
                  <span>YouSee må kontakte kunden via SMS</span>
                </label>
              </div>
              <div className="order__spacing__checkbox">
                <div className="ys-checkbox">
                  <input
                    type="checkbox"
                    name="contactOption"
                    id="7"
                    value="email"
                    checked={contactOptionEmail}
                    onChange={() =>
                      setContactOptionEmail((prevState) => !prevState)
                    }
                    className="ys-checkbox__control ys-u-visuallyhidden"
                  />
                  <label htmlFor="7" className="ys-checkbox__label">
                    <span>YouSee må kontakte kunden via E-mail</span>
                  </label>
                </div>
              </div>
            </section>
            <Button
              addClass="order__button"
              value="Færdiggør"
              loading={isLoading}
              onClick={finishOrder}
            />
          </div>
        )}
      </section>
    </div>
  );
};

export default Order;
