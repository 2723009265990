import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

class Checkmark extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['large', 'small']),
    className: PropTypes.string,
    isResponsive: PropTypes.bool, // Only applicable for `large`
    animationDelay: PropTypes.number, // Used to delay the "check" animation (e.g. when shown in a modal that fades in)
    white: PropTypes.bool, // If you want it white instead of green
  };

  static defaultProps = {
    size: 'large',
    animationDelay: 0,
  };

  state = {
    isMounted: false,
  };

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ isMounted: true });
    }, this.props.animationDelay);
  };

  render() {
    const { isResponsive, size, white } = this.props;
    const classes = classNames(
      'component-checkmark',
      `component-checkmark--${size}`,
      {
        'component-checkmark--responsive': isResponsive,
        'component-checkmark--white': white,
        'component-checkmark--ticked': this.state.isMounted,
      },
      this.props.className
    );

    return (
      <div className={classes} aria-label="Checked">
        <span className="component-checkmark__line component-checkmark__line--down"></span>
        <span className="component-checkmark__line component-checkmark__line--up"></span>
      </div>
    );
  }
}

export default Checkmark;
