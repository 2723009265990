import { React, useState, useEffect } from 'react';
import { loginSalesPerson, getSalesPersons } from '../../api/endpoints';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

import Header from '../../modules/Header';
import TextInput from '../../modules/TextInput';
import Button from '../../modules/Button';
import LoginIllustration from '../../assets/login-illustration.svg';

import './style.scss';

const Login = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [salesPeople, setSalesPeople] = useState([]);
  const [error, setError] = useState();
  const history = useHistory();

  const auth = useAuth();

  useEffect(() => {
    const fetchSalesPeople = async () => {
      const dealercode = auth?.user?.profile?.storenumber;

      const response = await getSalesPersons(dealercode);

      if (response.status === 200) {
        try {
          const body = await response.json();

          let sortedSalesPeople = body.sort((a, b) => {
            if (a.salesmanname && b.salesmanname) {
              return a.salesmanname.localeCompare(b.salesmanname);
            } else {
              return a.salesmancode.localeCompare(b.salesmancode);
            }
          });

          setSalesPeople(sortedSalesPeople);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchSalesPeople();
  }, [auth]);

  const login = async () => {
    setError('');
    const dealercode = auth?.user?.profile?.storenumber;

    const response = await loginSalesPerson({
      dealercode,
      password,
      salesmancode: username,
    });

    if (response.status === 200) {
      try {
        const body = await response.json();

        if (!body.dealercode) {
          setError('Noget gik galt, prøv igen');
        } else {
          sessionStorage.setItem('user', JSON.stringify(body));
          history.push('/mobil');
        }
      } catch (err) {
        setError(err);
      }
    } else {
      setError('Login forkert, prøv igen');
    }
  };

  return (
    <>
      <Header isLoginPage />
      <article className="login col-10 container">
        <header className="login__header">
          <h3 className="login__header__sub-title">Login</h3>
          <h1 className="login__header__title">Velkommen til YouSee</h1>
        </header>
        <form action="#" className="login__form col-12 col-lg-4">
          <div className="ys-select login__select">
            <label htmlFor="select" className="ys-input-field__label">
              <span className="ys-select__label-text">Sælger</span>
              <span className="ys-select__wrapper">
                <select
                  className="ys-select__control"
                  id="select"
                  value={username}
                  defaultValue="null"
                  onChange={(e) => setUsername(e.target.value)}
                >
                  <option value="null" disabled>
                    Vælg Sælger
                  </option>
                  {salesPeople.map((person, index) => {
                    const key = `person-${index}`;

                    if (person.salesmanname) {
                      return (
                        <option key={key} value={person.salesmancode}>
                          {person.salesmanname}
                        </option>
                      );
                    } else {
                      return (
                        <option key={key} value={person.salesmancode}>
                          {person.salesmancode}
                        </option>
                      );
                    }
                  })}
                </select>
              </span>
            </label>
          </div>
          <TextInput
            name="password"
            label="Sælgerkode"
            type="password"
            placeholder="Indtast din sælgerkode"
            onChange={(name, value) => setPassword(value)}
          />
          <Button
            display="inline"
            value="Log Ind"
            onClick={(e) => {
              e.preventDefault();
              login();
            }}
          />

          {error && <p>{error}</p>}
        </form>
        <img
          src={LoginIllustration}
          alt="Girl with computer"
          className="login__illustration"
        />
      </article>
    </>
  );
};

export default Login;
