import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import '@youseedk/dna/ys-bundle.min.css';

const oidcConfig = {
  authority:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'https://fed.tdc.dk'
      : 'https://fedpoc.pp.tdc.dk',
  client_id: 'Panda',
  client_secret:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'Jon2RYasCwmbZvSKt2TNWpMvqhJLLN5KerSIVPks8ns26oIE9IGLGJb8GwGljrym'
      : 'tcMPNsk09ycCVsjNtrWfbHBIHR1C7B07ri26PAfpMlsG1VMqqFyu9Qcb292AcvmP',
  redirect_uri: window.location.origin,
  scope: 'openid',
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
