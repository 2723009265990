import React, { useState, useEffect } from 'react';
import Button from '../../modules/Button';
import './style.scss';
import { getSubscriptions } from '../../api/endpoints';
import Subscription from './Subscription';

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
  EXISTING_EXTRA_UPGRADE: 'EXISTING_EXTRA_UPGRADE',
  EXISTING_NEW_SUBSCRIPTION: 'EXISTING_NEW_SUBSCRIPTION',
};

const tries = 3;
let tryNum = 0;

const Subscriptions = (props) => {
  const {
    setIsCompleted,
    setSelectedSubscription,
    selectedSubscription,
    originalSubscription,
    extraSubscriptions,
    setExtraSubscriptions,
    existingExtras,
    existingType,
    setIsStandAlone,
  } = props;

  const [subscriptions, setSubscriptions] = useState();
  const [extraSubscriptionsAvailable, setExtraSubscriptionsAvailable] =
    useState();
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSubs = async () => {
      tryNum++;
      const response = await getSubscriptions();

      try {
        const products = await response.json();
        setSubscriptions(products);

        if (existingType === types.EXISTING_ADD_EXTRA) {
          const singleSub = products.filter(
            (sub) => sub.topLevelProduct.productCode === selectedSubscription
          )[0];

          if (
            singleSub.topLevelProduct.extraSubscriptions &&
            singleSub.topLevelProduct.extraSubscriptions.availableSubscriptions
          ) {
            //We create a fake extra sub for now, as we're missing product features
            let fakeExtraSub =
              singleSub.topLevelProduct.extraSubscriptions.products[0];
            fakeExtraSub.productFeatures =
              singleSub.topLevelProduct.productFeatures;
            fakeExtraSub.includedAddons =
              singleSub.topLevelProduct.includedAddons;

            const extraSubs = Array.apply(
              null,
              Array(
                singleSub.topLevelProduct.extraSubscriptions
                  .availableSubscriptions
              )
            ).map(() => fakeExtraSub);
            setExtraSubscriptionsAvailable(extraSubs);
          }
        }
      } catch (error) {
        //It seems to almost always be that it didnt ready the auth yet
        if (tryNum < tries) {
          setTimeout(() => {
            fetchSubs();
          }, 200);
        }
      }
    };

    fetchSubs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let continueText = extraSubscriptions?.length
    ? `Fortsæt med ${extraSubscriptions.length + 1} abonnementer`
    : 'Fortsæt uden ekstra';

  if (existingType === types.EXISTING_ADD_EXTRA) {
    continueText = extraSubscriptions?.length
      ? `Fortsæt med ${extraSubscriptions.length} abonnementer`
      : 'Fortsæt uden ekstra';
  }

  if (!subscriptions?.length) {
    return <h2>Loading...</h2>;
  }

  const onContinue = () => {
    if (selectedSubscription) {
      setIsCompleted();
    } else {
      setError('Vælg mindst ét abonnement!');
    }
  };

  const atlSubscriptions = subscriptions.filter(
    (sub) =>
      (sub.atl && sub.topLevelProduct.productName !== 'Mobil 1 Time / 3 GB') ||
      sub.topLevelProduct.productName === 'Fri + 20 GB'
  );
  const btlSubscriptions = subscriptions.filter(
    (sub) =>
      (!sub.atl && sub.topLevelProduct.productName !== 'Fri + 20 GB') ||
      sub.topLevelProduct.productName === 'Mobil 1 Time / 3 GB'
  );

  const onlyExtra = existingType === types.EXISTING_ADD_EXTRA;
  const onlyStandAlone = existingType === types.EXISTING_EXTRA_UPGRADE;

  console.log('wtf');

  return (
    <section className="subscriptions">
      <table className="subscriptions__table">
        <thead className="subscriptions__table__head">
          <tr className="subscriptions__table__head__top">
            <td />
            <td colSpan="2">Data</td>
            <td colSpan="2">Tale</td>
            <td colSpan="2">Yousee Musik</td>
            <td colSpan="2">Netværk</td>
            <td />
          </tr>
          <tr className="subscriptions__table__head__bottom">
            <td />
            <td>DK</td>
            <td>World / EU</td>
            <td>World</td>
            <td>Frit i DK</td>
            <td colSpan="2">+75 mio numre</td>
            <td colSpan="2">4G altid inkl.</td>
            <td />
          </tr>
        </thead>
        <tbody>
          {!onlyExtra &&
            !onlyStandAlone &&
            atlSubscriptions &&
            atlSubscriptions.map((subscription, index) => {
              const key = `subscriptions-list-${index}`;

              let buttonText = 'Vælg';
              let currentSub = false;
              const { topLevelProduct } = subscription;
              const currentSelected =
                selectedSubscription === topLevelProduct.productCode;

              const onContinue = () => {
                setIsStandAlone(false);
                setSelectedSubscription(topLevelProduct.productCode);
                setExtraSubscriptions([]);
                setExtraSubscriptionsAvailable([]);

                if (
                  topLevelProduct.extraSubscriptions &&
                  topLevelProduct.extraSubscriptions.availableSubscriptions
                ) {
                  //We create a fake extra sub for now, as we're missing product features
                  let fakeExtraSub =
                    topLevelProduct.extraSubscriptions.products[0];
                  fakeExtraSub.productFeatures =
                    topLevelProduct.productFeatures;
                  fakeExtraSub.includedAddons = topLevelProduct.includedAddons;

                  console.log(fakeExtraSub);

                  const extraSubs = Array.apply(
                    null,
                    Array(
                      topLevelProduct.extraSubscriptions.availableSubscriptions
                    )
                  ).map(() => fakeExtraSub);
                  setExtraSubscriptionsAvailable(extraSubs);
                }

                if (currentSelected) {
                  setSelectedSubscription(null);
                  setExtraSubscriptions([]);
                  setExtraSubscriptionsAvailable([]);
                }
              };

              if (originalSubscription === topLevelProduct.productCode) {
                currentSub = true;
                buttonText = 'Genbestil';
              }

              if (currentSelected) {
                buttonText = 'Valgt';
              }

              return (
                <Subscription
                  key={key}
                  subscription={topLevelProduct}
                  currentSub={currentSub}
                  currentSelected={currentSelected}
                  onContinue={onContinue}
                  buttonText={buttonText}
                />
              );
            })}
          {!onlyExtra &&
            extraSubscriptionsAvailable &&
            !!extraSubscriptionsAvailable.length && (
              <tr className="subscriptions__table__spacer">
                <td className="subscriptions__table__label subscriptions__table__label--large">
                  Ekstra abonnementer
                </td>
              </tr>
            )}
          {extraSubscriptionsAvailable &&
            extraSubscriptionsAvailable.map((subscription, index) => {
              const key = `subscriptions-list-${index}`;

              if (existingExtras && index + existingExtras > 5) {
                return null;
              }

              let buttonText = 'Vælg';
              let currentSub = false;
              const currentSelected =
                extraSubscriptions &&
                extraSubscriptions[index] === subscription.productCode;

              const onContinue = () => {
                setIsStandAlone(false);
                const selected = Array.apply(null, Array(index + 1)).map(
                  () => subscription.productCode
                );
                setExtraSubscriptions(selected);

                if (
                  currentSelected &&
                  extraSubscriptions.length === index + 1
                ) {
                  setExtraSubscriptions(null);
                }
              };

              if (originalSubscription === subscription.productCode) {
                currentSub = true;
                buttonText = 'Genbestil';
              }

              if (currentSelected) {
                buttonText = 'Valgt';
              }

              return (
                <Subscription
                  key={key}
                  number={index + 1}
                  subscription={subscription}
                  currentSub={currentSub}
                  currentSelected={currentSelected}
                  onContinue={onContinue}
                  buttonText={buttonText}
                />
              );
            })}
          {!onlyExtra && (
            <tr className="subscriptions__table__spacer">
              <td className="subscriptions__table__label subscriptions__table__label--large">
                Nedenstående er uden mobilrabat
              </td>
            </tr>
          )}
          {!onlyExtra &&
            btlSubscriptions &&
            btlSubscriptions.map((subscription, index) => {
              const key = `subscriptions-list-${index}`;

              let buttonText = 'Vælg';
              let currentSub = false;
              const { topLevelProduct } = subscription;
              const currentSelected =
                selectedSubscription === topLevelProduct.productCode;

              const onContinue = () => {
                if (currentSelected) {
                  setIsStandAlone(false);
                  setSelectedSubscription(null);
                } else {
                  setIsStandAlone(true);
                  setSelectedSubscription(topLevelProduct.productCode);
                }

                setExtraSubscriptions([]);
                setExtraSubscriptionsAvailable([]);

                if (
                  topLevelProduct.extraSubscriptions &&
                  topLevelProduct.extraSubscriptions.availableSubscriptions
                ) {
                  //We create a fake extra sub for now, as we're missing product features
                  let fakeExtraSub =
                    topLevelProduct.extraSubscriptions.products[0];
                  fakeExtraSub.productFeatures =
                    topLevelProduct.productFeatures;
                  fakeExtraSub.includedAddons = topLevelProduct.includedAddons;

                  const extraSubs = Array.apply(
                    null,
                    Array(
                      topLevelProduct.extraSubscriptions.availableSubscriptions
                    )
                  ).map(() => fakeExtraSub);
                  setExtraSubscriptionsAvailable(extraSubs);
                }
              };

              if (originalSubscription === topLevelProduct.productCode) {
                currentSub = true;
                buttonText = 'Genbestil';
              }

              if (currentSelected) {
                buttonText = 'Valgt';
              }

              return (
                <Subscription
                  key={key}
                  subscription={topLevelProduct}
                  currentSub={currentSub}
                  currentSelected={currentSelected}
                  onContinue={onContinue}
                  buttonText={buttonText}
                />
              );
            })}
        </tbody>
      </table>
      <div className="container">
        <Button
          addClass="subscriptions__button"
          value={continueText}
          display="inline"
          onClick={() => onContinue()}
        />
        {error && <p>{error}</p>}
      </div>
    </section>
  );
};

export default Subscriptions;
