import React, { useState, useEffect, useCallback } from 'react';
import Button from '../../modules/Button/';
import TextInput from '../../modules/TextInput/';
import SearchIcon from '../../assets/search.svg';
import './datepicker.scss';
import DatePicker, { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import { addDays, format } from 'date-fns';
import {
  getNumbers,
  reserveNumber,
  addNumberAndICC,
  setNumberListing,
  portNumber,
  setSaldoMax,
  setDevice,
  removeDevice,
  removeAddon,
  addAddon,
} from '../../api/endpoints';
registerLocale('da', da);

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
  EXISTING_NEW_SUBSCRIPTION: 'EXISTING_NEW_SUBSCRIPTION',
};

const NumberConfig = (props) => {
  const {
    basket,
    subscription,
    onContinue,
    email,
    existingType,
    setProductBasket,
    preSelectedNumber,
    isStandAlone,
  } = props;

  const isWeekday = (dateObj: Date) => {
    const day = dateObj.getDay();
    return day !== 0 && day !== 6;
  };

  const determineMinPortDate = (date: Date): any => {
    // Recursively increases port date until it is a working day
    return isWeekday(date) ? date : determineMinPortDate(addDays(date, 1));
  };

  const setMinPortDate = () => {
    const fiveDaysFromNow = addDays(new Date(), 1);
    const minPortDate = determineMinPortDate(fiveDaysFromNow);

    return minPortDate;
  };

  //msisdn related
  const [numbers, setNumbers] = useState({});
  const [searchNumbers, setSearchNumbers] = useState([
    { number: 1, set: false },
    { number: 2, set: false },
    { number: 3, set: false },
    { number: 4, set: false },
    { number: 5, set: false },
    { number: 6, set: false },
    { number: 7, set: false },
    { number: 8, set: false },
  ]);
  const [selectedNumber, setSelectedNumber] = useState();

  const [activationDate, setActivationDate] = useState(setMinPortDate());
  const [minPortDate] = useState(setMinPortDate());
  const [activationDateType, setActivationDateType] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [forceChange, setForceChange] = useState(false);

  const [genericError, setGenericError] = useState('');
  const [simNumber, setSimNumber] = useState();
  const [showSim, setShowSim] = useState(true);
  const [originalSim, setOriginalSim] = useState();
  const [confirmOwner, setConfirmOwner] = useState(false);
  const [tab, setTab] = useState(1);

  const [listed, setListed] = useState('NOT_IN_YELLOW_PAGES');
  const [imeiNumber, setImeiNumber] = useState();

  //Saldomax
  const [selectedSaldomax, setSelectedSaldomax] = useState(false);
  const [saldoMaxAmount, setSaldoMaxAmount] = useState();
  const [pin, setPin] = useState();

  //Data sharing
  const [dataSharingIsAvailable, setDataSharingIsAvailable] = useState(false);
  const [dataSharing, setDataSharing] = useState(false);
  const [dataSharingSim, setDataSharingSim] = useState('');

  let shouldChange =
    !existingType ||
    !subscription.subscriptionNumber ||
    (!!(
      existingType === types.EXISTING_ADD_EXTRA ||
      existingType === types.EXISTING_NEW_SUBSCRIPTION
    ) &&
      !subscription.subscriptionNumber);

  const fetchNumbers = async (pattern) => {
    setNumbers(null);
    const response = await getNumbers(pattern);

    try {
      const numbers = await response.json();
      setNumbers(numbers);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleTab = useCallback(
    (index) => {
      setTab(index);

      if (index === 2 && !numbers?.length) {
        fetchNumbers();
      }
    },
    [setTab, numbers?.length]
  );

  const setReserveNumber = async () => {
    const response = await reserveNumber(selectedNumber);

    try {
      const body = await response.json();

      if (body?.statusCode !== '0') {
        return body.statusMessage;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setNumberInBasket = async () => {
    const response = await addNumberAndICC(
      basket.productBasketIds[0],
      subscription.subscriptionIndex,
      {
        icc: simNumber,
        mobileNumber: selectedNumber,
      }
    );

    try {
      const basket = await response.json();
      if (basket?.result !== 'SUCCESS') {
        return basket.message;
      } else {
        setProductBasket(basket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setListing = async () => {
    const response = await setNumberListing(
      basket.productBasketIds[0],
      subscription.subscriptionIndex,
      listed
    );

    try {
      const basket = await response.json();
      if (basket?.result !== 'SUCCESS') {
        return basket.message;
      } else {
        setProductBasket(basket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setSaldoInBasket = async () => {
    const response = await setSaldoMax(
      basket.productBasketIds[0],
      subscription.subscriptionIndex,
      {
        amount: saldoMaxAmount,
        pin,
      }
    );

    try {
      const basket = await response.json();
      if (basket?.result !== 'SUCCESS') {
        return basket.message;
      } else {
        setProductBasket(basket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setIMEI = async () => {
    if (!imeiNumber || (imeiNumber && imeiNumber.length < 15)) {
      return 'IMEI skal være 15 karakterer langt';
    }
    const response = await setDevice(
      basket.productBasketIds[0],
      subscription.subscriptionIndex,
      {
        imeiNumber,
        modelName: ' ',
      }
    );

    try {
      const basket = await response.json();
      if (basket?.result !== 'SUCCESS') {
        return basket.message;
      } else {
        setProductBasket(basket);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPortNumber = async () => {
    const response = await portNumber(
      basket.productBasketIds[0],
      subscription.subscriptionIndex,
      {
        currentIcc: originalSim,
        importDate:
          activationDateType === 2
            ? format(activationDate, 'yyyy-MM-dd')
            : null,
        mobileNumber: selectedNumber,
        newIcc: simNumber,
        poaMail: email,
      }
    );

    try {
      const body = await response.json();
      if (body?.result !== 'SUCCESS') {
        return body.message;
      } else {
        setProductBasket(body);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const finishNumber = async () => {
    setIsLoading(true);
    setGenericError('');

    if (shouldChange && tab === 1) {
      //It's move number
      const setPortNumberError = await setPortNumber();

      if (setPortNumberError) {
        setIsLoading(false);
        setGenericError(setPortNumberError);
        return;
      }
    } else if (shouldChange) {
      //It's new number - But if the number is already selected, it's already reserved and we should reserve again
      if (preSelectedNumber !== selectedNumber) {
        const setNumberInBasketError = await setReserveNumber();

        if (setNumberInBasketError) {
          setIsLoading(false);
          setGenericError(setNumberInBasketError);
          return;
        }

        //Try to set the number in basket
        const numberSetError = await setNumberInBasket();

        if (numberSetError) {
          setIsLoading(false);
          setGenericError(numberSetError);
          return;
        }
      }
    }

    //Try to set listing
    if (shouldChange) {
      const listingError = await setListing();

      if (listingError) {
        setIsLoading(false);
        setGenericError(listingError);
        return;
      }
    }

    //If saldomax, try that as well
    if (selectedSaldomax) {
      const saldoMaxError = await setSaldoInBasket();

      if (saldoMaxError) {
        setIsLoading(false);
        setGenericError(saldoMaxError);
        return;
      }
    } else {
      //We need to figure if there's saldomax, and remove it
      const saldoSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'SALDOMAX';
      });
      if (saldoSettings && saldoSettings.length) {
        try {
          if (saldoSettings[0][1].selected) {
            const removeResponse = await removeAddon(
              basket.productBasketIds[0],
              saldoSettings[0][0]
            );

            const removeBody = await removeResponse.json();

            if (removeBody?.result !== 'SUCCESS') {
              setGenericError(removeBody.message);
            } else {
              setProductBasket(removeBody);
            }
          }
        } catch (error) {
          //Nothing
        }
      }
    }

    //If data sharing, we try that as well
    if (dataSharing) {
      const dataSharingSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'DATADEL_01';
      });

      const sharingResponse = await addAddon(
        basket.productBasketIds[0],
        dataSharingSettings[0][0],
        [
          {
            code: 'DATADEL_ICC',
            value: dataSharingSim,
          },
        ]
      );

      const sharingBody = await sharingResponse.json();

      if (sharingBody?.result !== 'SUCCESS') {
        setIsLoading(false);
        setGenericError(sharingBody.message);
        return;
      }
    } else {
      const dataSharingSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'DATADEL_01';
      });
      //We remove data sharing if it exists, but isn't chosen???
      if (
        dataSharingSettings &&
        dataSharingSettings.length &&
        dataSharingSettings[0].length &&
        dataSharingSettings[0][1]?.enabled
      ) {
        const removeResponse = await removeAddon(
          basket.productBasketIds[0],
          dataSharingSettings[0][0]
        );

        const removeBody = await removeResponse.json();

        if (removeBody?.result !== 'SUCCESS') {
          setGenericError(removeBody.message);
        } else {
          setProductBasket(removeBody);
        }
      }
    }

    //If IMEI, try that as well
    if (imeiNumber) {
      const imeiError = await setIMEI();

      if (imeiError) {
        setIsLoading(false);
        setGenericError(imeiError);
        return;
      }
    } else {
      //If there's no imei, call delete on it to make sure basket reflects
      const removeResponse = await removeDevice(
        basket.productBasketIds[0],
        subscription.subscriptionIndex
      );

      const removeBody = await removeResponse.json();

      if (removeBody?.result !== 'SUCCESS') {
        setGenericError(removeBody.message);
      } else {
        setProductBasket(removeBody);
      }
    }

    setIsLoading(false);
    onContinue();
  };

  const searchForNumbers = () => {
    const hasAnySet = searchNumbers.find((number) => number.set);

    if (hasAnySet) {
      let pattern = ['%', '%', '%', '%', '%', '%', '%', '%'];

      searchNumbers.forEach((number, index) => {
        if (number.set) {
          pattern[index] = number.number;
        }
      });

      fetchNumbers(pattern.join(''));
    } else {
      fetchNumbers();
    }
  };

  useEffect(() => {
    const addons = Object.entries(subscription.addons);

    const numberSettings = addons.filter((addon) => {
      return addon[1].groupClass === 'NUMBER_TYPE';
    });

    const phoneSettings = addons.filter((addon) => {
      return addon[1].groupClass === 'TERM';
    });

    const saldoSettings = addons.filter((addon) => {
      return addon[1].addonClass === 'SALDOMAX';
    });

    const listedSettings = addons.filter((addon) => {
      return addon[1].addonClass === 'LISTED';
    });

    const numberType = numberSettings.filter((addon) => {
      return addon[1].selected;
    });

    const dataSharingSettings = addons.filter((addon) => {
      return addon[1].addonClass === 'DATADEL_01';
    });

    if (dataSharingSettings && dataSharingSettings.length) {
      try {
        if (dataSharingSettings[0][1]?.enabled) {
          setDataSharingIsAvailable(true);
        }

        if (dataSharingSettings[0][1]?.selected) {
          setDataSharing(true);
        }

        if (
          dataSharingSettings[0][1]?.selected &&
          dataSharingSettings[0][1]?.parameters[0]?.newValue
        ) {
          setDataSharingSim(dataSharingSettings[0][1]?.parameters[0]?.newValue);
        }
      } catch (error) {
        //Nothing
      }
    }

    //Try finding IMEI from basket and add it
    if (phoneSettings && phoneSettings.length) {
      try {
        if (phoneSettings[0][1].selected) {
          setImeiNumber(
            phoneSettings[0][1].parameters.find(
              (param) => param.metaData.code === 'IMEI'
            ).newValue
          );
        }
      } catch (error) {
        //Nothing
      }
    }

    //Try finding saldomax from basket and adding it as well
    if (saldoSettings && saldoSettings.length) {
      try {
        if (saldoSettings[0][1].selected) {
          setSaldoMaxAmount(
            saldoSettings[0][1].parameters.find(
              (param) => param.metaData.code === 'SALDOMAKS_BELOEB2'
            ).newValue
          );
          setPin(
            saldoSettings[0][1].parameters.find(
              (param) => param.metaData.code === 'SALDOMAKS_PINKODE'
            ).newValue
          );

          if (
            saldoSettings[0][1].parameters.find(
              (param) => param.metaData.code === 'SALDOMAKS_PINKODE'
            ).newValue
          ) {
            setSelectedSaldomax(true);
          }
        }
      } catch (error) {
        //Nothing
      }
    }

    //Try finding HUA marking from basket and adding it as well
    if (listedSettings && listedSettings.length) {
      try {
        if (listedSettings[0][1].selected) {
          const listedMap = {
            H: 'SECRET_NUMBER',
            U: 'NOT_IN_YELLOW_PAGES',
            A: 'SECRET_ADDRESS',
            L: 'PUBLIC_AND_LISTED',
          };

          setListed(
            listedMap[
              listedSettings[0][1].parameters.find(
                (param) => param.metaData.code === 'HUA_MARKERING'
              ).newValue
            ]
          );
        }
      } catch (error) {
        //Nothing
      }
    }

    //Try find number from basket and set it (alongside type)
    if (numberType && numberType.length) {
      try {
        if (numberType[0][1].name !== 'Nummerportering') {
          toggleTab(2);
        }

        setSelectedNumber(
          numberType[0][1].parameters.find(
            (param) =>
              param.metaData.code === 'MSISDN' ||
              param.metaData.code === 'NPMSISDN'
          ).newValue
        );
        setSimNumber(
          numberType[0][1].parameters.find(
            (param) =>
              param.metaData.code === 'SIM' || param.metaData.code === 'ICC'
          ).newValue
        );

        //this has to be last as it's likely to fail
        setOriginalSim(
          numberType[0][1].parameters.find(
            (param) => param.metaData.code === 'CURRENT_ICC'
          ).newValue
        );
      } catch (error) {
        //Nothing
      }
    }
  }, [subscription.addons, toggleTab]);

  const addons = Object.entries(subscription.addons);

  const numberSettings = addons.filter((addon) => {
    return addon[1].groupClass === 'NUMBER_TYPE';
  });

  const numberType = numberSettings.filter((addon) => {
    return addon[1].selected;
  });

  const isNewNumber =
    numberType &&
    numberType.length &&
    numberType[0][1].name !== 'Nummerportering';

  //If selectednumber exists, but isn't in the numbers array, we add it manually to make sure it stays
  if (
    selectedNumber &&
    isNewNumber &&
    numbers &&
    numbers.length &&
    !numbers.filter((number) => number.mobileNumber === selectedNumber).length
  ) {
    numbers.unshift({
      mobileNumber: selectedNumber,
    });
  }

  if (numbers && numbers.length > 6) {
    numbers.pop();
  }

  return (
    <section className="order__number-config">
      <h1 className="order__configured__title">{subscription.productName}</h1>
      {!shouldChange && subscription && subscription.subscriptionNumber && (
        <>
          <h2 className="order__configured__number">
            Gentegn:{' '}
            {subscription.subscriptionNumber.match(/.{1,2}/g).join(' ')}
          </h2>
          <Button
            value="Ændre"
            className="order__button"
            onClick={() => setForceChange(true)}
          />
        </>
      )}
      {(shouldChange || forceChange) && (
        <>
          {shouldChange && (
            <div
              className="ys-tabs order__tabs"
              role="tablist"
              aria-label="Select to keep number or new number"
            >
              <button
                className="ys-tabs__tab order__tabs__tab"
                role="tab"
                aria-selected={tab === 1 ? 'true' : 'false'}
                aria-controls="tabTarget01"
                onClick={() => toggleTab(1)}
                id="tab01"
              >
                Flyt nummer
              </button>
              <button
                className="ys-tabs__tab order__tabs__tab"
                role="tab"
                aria-selected={tab === 2 ? 'true' : 'false'}
                aria-controls="tabTarget02"
                onClick={() => toggleTab(2)}
                id="tab02"
                tabIndex="-1"
              >
                Nyt nummer
              </button>
            </div>
          )}
          {tab === 1 && (
            <div>
              <span className="order__section__title">Flyt nummer</span>
              <p>
                YouSee opsiger abonnementet hos kundens nuværende mobilselskab.
                Vi skal bare have kundens telefonnummer, simkortnummer og
                tilladelse. Vi skal bruge kundens simkortnummer af
                sikkerhedsmæssige årsager, for at sikre at det er det rigtige
                telefonabonnement vi flytter.
              </p>
              <section className="order__section-content">
                <TextInput
                  className="order__details__sim"
                  onChange={(name, value) => setSelectedNumber(value)}
                  name="telefon"
                  placeholder="Telefonnummer"
                  value={selectedNumber}
                  type="tel"
                  maxLength="8"
                  label="Telefonnumer"
                  numberOnly={true}
                />
              </section>
              <section className="order__section-content">
                <TextInput
                  className="order__details__sim"
                  onChange={(name, value) => setOriginalSim(value)}
                  name="sim"
                  placeholder="Simkortnummer"
                  label="Nuværende simkortnummer"
                  value={originalSim}
                  type="tel"
                  maxLength="20"
                  numberOnly={true}
                />
              </section>
              <div className="ys-checkbox">
                <input
                  type="checkbox"
                  name="contactOption"
                  id="confirm-owner"
                  value="owner"
                  checked={confirmOwner}
                  onChange={() => setConfirmOwner((prevState) => !prevState)}
                  className="ys-checkbox__control ys-u-visuallyhidden"
                />
                <label htmlFor="confirm-owner" className="ys-checkbox__label">
                  <span>
                    Kunden bekræfter at være den juridiske ejer af
                    telefonnummeret og at Yousee må opsige det hos det nuværende
                    mobilselskab.
                  </span>
                </label>
              </div>
            </div>
          )}
          {tab === 2 && (
            <section>
              <h2 className="order__section__title">Vælg et nyt nummer</h2>
              <div className="order__details__numbers__number-search-wrap">
                <h3>Søg nummer</h3>
                <p>Indtast 1 eller flere tal i talrækken</p>
                {searchNumbers.map((number, index) => {
                  const onChange = (e) => {
                    const localNumbers = [...searchNumbers];
                    if (e.target.value && e.target.value.length > 1) {
                      return false;
                    }

                    if (e.target.value) {
                      localNumbers[index].number = e.target.value;
                      localNumbers[index].set = true;

                      if (e.target.nextSibling) {
                        e.target.nextSibling.focus();
                      }
                    } else {
                      localNumbers[index].number = index + 1;
                      localNumbers[index].set = false;
                    }

                    setSearchNumbers(localNumbers);
                  };

                  if (number.set) {
                    return (
                      <input
                        type="text"
                        className="order__details__numbers__number-search"
                        max={1}
                        onChange={(e, value) => onChange(e, value)}
                        value={number.number}
                      />
                    );
                  } else {
                    return (
                      <input
                        type="text"
                        className="order__details__numbers__number-search"
                        max={1}
                        onChange={(e, value) => onChange(e, value)}
                        placeholder="?"
                      />
                    );
                  }
                })}
                <Button
                  className="order__details__numbers__number-search__button"
                  svgIcon={<img alt="Søg" src={SearchIcon} />}
                  onClick={() => searchForNumbers()}
                />
              </div>
              {(!numbers || !numbers.length) && <h2>Loading...</h2>}
              <ul className="order__details__numbers ys-row">
                {numbers &&
                  numbers.length &&
                  numbers.map((number, index) => {
                    const key = `numbers-${index}`;
                    return (
                      <li
                        key={key}
                        className={`order__details__numbers__number ys-col-6 ys-col-md-4 ${
                          selectedNumber === number.mobileNumber &&
                          'order__details__numbers__number--selected'
                        }`}
                      >
                        <div className="ys-radio-button">
                          <input
                            type="radio"
                            name="number"
                            id={key}
                            value={number.mobileNumber}
                            className="ys-radio-button__control ys-u-visuallyhidden"
                            checked={selectedNumber === number.mobileNumber}
                            onChange={() =>
                              setSelectedNumber(number.mobileNumber)
                            }
                          />
                          <label
                            htmlFor={key}
                            className="ys-radio-button__label"
                          >
                            <span data-hj-suppress>
                              <strong>
                                {number.mobileNumber
                                  .match(/.{1}/g)
                                  .map((num, index) => {
                                    if (
                                      searchNumbers[index].set &&
                                      searchNumbers[index].number === num
                                    ) {
                                      return (
                                        <span className="green">{num}</span>
                                      );
                                    }

                                    return <span>{num}</span>;
                                  })}
                              </strong>
                            </span>
                          </label>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <p className="order__more-numbers" onClick={() => fetchNumbers()}>
                Se flere numre
              </p>
            </section>
          )}
          <hr />
          <section className="order__section">
            <div className="ys-checkbox">
              <input
                type="checkbox"
                name="showSIm"
                id="show-sim"
                value="owner"
                checked={!showSim}
                onChange={() => {
                  setShowSim((prevState) => !prevState);
                  setSimNumber();
                }}
                className="ys-checkbox__control ys-u-visuallyhidden"
              />
              <label htmlFor="show-sim" className="ys-checkbox__label">
                <span>
                  Nyt simkort skal eftersendes med post til CPR adresse.
                </span>
              </label>
            </div>
            <br />
            {showSim && (
              <TextInput
                className="order__details__sim"
                label="Nyt YouSee Simkortnummer"
                onChange={(e, value) => setSimNumber(value)}
                value={simNumber}
                name="sim"
                placeholder="Scan eller indtast simkortnummer"
                type="tel"
                maxLength="20"
                numberOnly={true}
              />
            )}
          </section>
          <hr />
          {dataSharingIsAvailable && (
            <section className="order__section">
              <div className="ys-checkbox">
                <input
                  type="checkbox"
                  name="showSIm"
                  id="show-data-sim"
                  value="owner"
                  checked={dataSharing}
                  onChange={() => {
                    setDataSharing((prevState) => !prevState);
                    setDataSharingSim();
                  }}
                  className="ys-checkbox__control ys-u-visuallyhidden"
                />
                <label htmlFor="show-data-sim" className="ys-checkbox__label">
                  <span>Tilføj datadelings simkort</span>
                </label>
              </div>
              <br />
              {dataSharing && (
                <TextInput
                  className="order__details__sim"
                  label="Nyt Datadelings Simkortnummer"
                  onChange={(e, value) => setDataSharingSim(value)}
                  value={dataSharingSim}
                  name="sim"
                  placeholder="Scan eller indtast simkortnummer"
                  type="tel"
                  maxLength="20"
                  numberOnly={true}
                />
              )}
            </section>
          )}
          <hr />
          {tab === 1 && (
            <div>
              <section className="order__section">
                <h2 className="order__section__title">
                  Vælg hvornår nummeret skal flyttes
                </h2>
                <div className="ys-radio-button">
                  <input
                    type="radio"
                    name="activationDateType"
                    id="9"
                    value="Efter endt opsigelses periode"
                    checked={activationDateType === 1}
                    onChange={() => setActivationDateType(1)}
                    className="ys-radio-button__control ys-u-visuallyhidden"
                  />
                  <label htmlFor="9" className="ys-radio-button__label">
                    <strong>Efter endt opsigelses periode</strong>
                    <h4 className="order__under-radio-label">
                      Kunden ønsker nummeret flyttet, når binding og/eller
                      opsigelsesperiode hos det nuværende teleselskab er
                      udløbet. Derved undgår kunden dobbeltbetaling.
                    </h4>
                  </label>
                </div>
                <div className="ys-radio-button">
                  <input
                    type="radio"
                    value="Vælg dato"
                    name="activationDateType"
                    id="10"
                    checked={activationDateType === 2}
                    onChange={() => setActivationDateType(2)}
                    className="ys-radio-button__control ys-u-visuallyhidden"
                  />
                  <label htmlFor="10" className="ys-radio-button__label">
                    <strong>Vælg dato</strong>
                    <h4 className="order__under-radio-label">
                      Ønskes en specifik dato for aktiveringen af simkortet?
                    </h4>
                    {activationDateType === 2 && (
                      <div>
                        <DatePicker
                          locale="da"
                          minDate={minPortDate}
                          maxDate={addDays(new Date(), 90)}
                          filterDate={isWeekday}
                          selected={activationDate}
                          onChangeRaw={(e) => e.preventDefault()}
                          onChange={(date) => setActivationDate(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                        <h4 className="order__under-radio-label">
                          Bemærk: Har kunden ønsket en dato, der ligger før at
                          bindingen eller opsigelsesperioden udløber hos den
                          nuværende udbyder? Så har udbyderen ret til at opkræve
                          betaling for den resterende del af kundens binding
                          eller opsigelsesperiode.
                        </h4>
                      </div>
                    )}
                  </label>
                </div>
              </section>
              <hr />
            </div>
          )}
          <section className="order__section">
            <h1 className="order__section__title">
              Vælg hvordan nummeret skal vises
            </h1>
            <div className="ys-radio-button">
              <input
                type="radio"
                name="Listed"
                id="1"
                value="Synligt navn, nummer og adresse"
                checked={listed === 'PUBLIC_AND_LISTED'}
                onChange={() => setListed('PUBLIC_AND_LISTED')}
                className="ys-radio-button__control ys-u-visuallyhidden"
              />
              <label htmlFor="1" className="ys-radio-button__label">
                <strong>Synligt navn, nummer og adresse</strong>
                <h4 className="order__under-radio-label">
                  Kunden registreres på 118, Krak og andre online søgetjenester.
                  Det betyder, at både navn, nummer og adresse kan søges frem.
                  Kan skiftes til Hemmeligt Nummer eller Udeladt Nummer på Mit
                  YouSee.
                </h4>
              </label>
            </div>
            <div className="ys-radio-button">
              <input
                type="radio"
                value="Udeladt nummer"
                name="Listed"
                id="2"
                checked={listed === 'NOT_IN_YELLOW_PAGES'}
                onChange={() => setListed('NOT_IN_YELLOW_PAGES')}
                className="ys-radio-button__control ys-u-visuallyhidden"
              />
              <label htmlFor="2" className="ys-radio-button__label">
                <strong>Udeladt nummer</strong>
                <h4 className="order__under-radio-label">
                  Med Udeladt Nummer bliver hverken navn, telefonnummer eller
                  adresse oplyst på 118, Krak eller andre online søgetjenester.
                </h4>
              </label>
            </div>
            <div className="ys-radio-button">
              <input
                type="radio"
                name="Listed"
                id="3"
                value="Hemmeligt nummer"
                checked={listed === 'SECRET_NUMBER'}
                onChange={() => setListed('SECRET_NUMBER')}
                className="ys-radio-button__control ys-u-visuallyhidden"
              />
              <label htmlFor="3" className="ys-radio-button__label">
                <strong>Hemmeligt nummer</strong>
                <h4 className="order__under-radio-label">
                  Med Hemmeligt Nummer bliver telefonnummeret ikke oplyst på
                  118, Krak eller andre online søgetjenester. Kun navn og
                  adresse kan søges frem.
                </h4>
              </label>
            </div>
          </section>
          <hr />
          <section className="order__section">
            <h1 className="order__section__title">Vælg Saldomax</h1>
            <p>
              Med Saldomax kan der vælges et maksimumsbeløb for forbrug for
              kundens mobilabonnement i en fakturaperiode. Når dette beløb er
              nået, spærres mobiltelefonen automatisk for yderligere forbrug.
            </p>
            <p>
              Fx, hvis kunden ringer meget til udlandet eller køber togbilletter
              via SMS, SMS-stemmer i live tv programmer eller støtter
              velgørenhed via SMS.
            </p>
            <div className="ys-radio-button">
              <input
                type="radio"
                name="selectedSaldomax"
                id="4"
                value={false}
                checked={selectedSaldomax === false}
                onChange={() => setSelectedSaldomax(false)}
                className="ys-radio-button__control ys-u-visuallyhidden"
              />
              <label htmlFor="4" className="ys-radio-button__label">
                <strong>Nej tak til Saldomax</strong>
              </label>
            </div>
            <div className="ys-radio-button">
              <input
                type="radio"
                name="selectedSaldomax"
                id="5"
                value={true}
                checked={selectedSaldomax === true}
                onChange={() => setSelectedSaldomax(true)}
                className="ys-radio-button__control ys-u-visuallyhidden"
              />
              <label htmlFor="5" className="ys-radio-button__label">
                <strong>Ja tak til Saldomax</strong>
              </label>
            </div>
            {selectedSaldomax && (
              <div>
                <div className="ys-select">
                  <label htmlFor="select" className="ys-input-field__label">
                    <span className="ys-select__label-text">Beløbsgrænse</span>
                    <span className="ys-select__wrapper">
                      <select
                        className="ys-select__control"
                        id="select"
                        value={saldoMaxAmount}
                        defaultValue="0"
                        onChange={(e) => setSaldoMaxAmount(e.target.value)}
                      >
                        <option value="0" disabled>
                          Vælg beløbsgrænse
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="25"
                        >
                          25 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="50"
                        >
                          50 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="75"
                        >
                          75 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="100"
                        >
                          100 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="150"
                        >
                          150 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="250"
                        >
                          250 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="500"
                        >
                          500 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="750"
                        >
                          750 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="1000"
                        >
                          1000 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="1500"
                        >
                          1500 kr.
                        </option>
                        <option
                          className="ys-select__option text-right"
                          value="3000"
                        >
                          3000 kr.
                        </option>
                      </select>
                    </span>
                  </label>
                </div>
                <TextInput
                  className="order__details__sim"
                  onChange={(name, value) => setPin(value)}
                  value={pin}
                  label="Saldomax pinkode"
                  name="pin"
                  placeholder="Opret pinkode (4 cifre)"
                  type="tel"
                  maxLength="4"
                  numberOnly={true}
                />
              </div>
            )}
          </section>
          <hr />
        </>
      )}
      {!isStandAlone && (
        <section className="order__section">
          <h1 className="order__section__title">Hvis køb af mobiltelefon</h1>
          <TextInput
            className="order__details__sim"
            value={imeiNumber}
            numberOnly={true}
            onChange={(name, value) => setImeiNumber(value)}
            name="imei"
            placeholder="Fx. 123456789101112"
            maxLength="15"
            label="Scan eller indtast IMEI nummer"
          />
        </section>
      )}
      <Button
        value="Næste"
        className="order__button"
        onClick={() => finishNumber()}
        loading={isLoading}
      />
      {genericError && <p>{genericError}</p>}
    </section>
  );
};

export default NumberConfig;
