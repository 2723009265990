const environment = (env) => {
  if (env === 'development') {
    return {
      env: 'development',
      api: {
        host: 'https://wpapi-proxy-test-wpapi-test.public.lqd.dk/',
      },
    };
  }

  return {
    env: 'production',
    api: {
      host: 'https://wpapi-proxy-prod-wpapi-prod.public.lqd.dk/',
    },
  };
};

export default environment(process.env.REACT_APP_ENVIRONMENT);
