import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.scss';

const Button = (props) => {
  const {
    value,
    onClick,
    loading,
    display,
    isLink,
    size,
    color,
    transparent,
    svgIcon,
    addClass,
    isResponsive,
    isDisabled,
    autoFocus,
    goToUrl,
    className,
  } = props;
  const componentClass = classNames(
    'component-button',
    `component-button--${size}`,
    `component-button--${color}`,
    `component-button--${display}`,
    addClass,
    className,
    {
      'component-button--link': isLink, // The link style is kinda special as it affects both size and color
      'component-button--loading': loading,
      'component-button--responsive': isResponsive,
      'component-button--disabled': isDisabled,
    }
  );

  const buttonClass = classNames('component-button__button', {
    'component-button__button--transparent': transparent,
    'component-button--link-button': goToUrl,
  });

  const svgIconAvailable = (
    <div className="component-button__button__icon">{svgIcon}</div>
  );

  return (
    <div className={componentClass}>
      {goToUrl ? (
        <Link to={goToUrl} className={buttonClass}>
          {value}
          {svgIcon && svgIconAvailable}
        </Link>
      ) : (
        <button
          disabled={isDisabled}
          className={buttonClass}
          aria-busy={loading}
          onClick={onClick}
          autoFocus={autoFocus}
        >
          {value}
          {svgIcon && svgIconAvailable}
        </button>
      )}
    </div>
  );
};

Button.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  display: PropTypes.oneOf(['block', 'inline', 'block-centered']),
  addClass: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['large', 'small']),
  color: PropTypes.oneOf([
    'primary',
    'dark',
    'light',
    'light-bordered',
    'default-light',
  ]),
  transparent: PropTypes.bool,
  isLink: PropTypes.bool,
  svgIcon: PropTypes.node,
  isResponsive: PropTypes.bool, // Only implemented for large buttons
  isDisabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  goToUrl: PropTypes.string,
};

Button.defaultProps = {
  display: 'block',
  size: 'large',
  color: 'primary',
};

export default Button;
