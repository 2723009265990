import React from 'react';

import Item from './item.js';
import './styles.scss';

const Accordion = (props) => {
  const { items, isCompleted, setIsCompleted, activeStep, setActiveStep } =
    props;

  const filteredItem = items.filter((item) => item !== false);

  return (
    <div className="accordion">
      {filteredItem.map((item, index) => {
        const key = `accordion-item-${index}`;

        if (isCompleted >= item.position || item.position === 0) {
          return (
            <Item
              key={key}
              {...item}
              setIsCompleted={setIsCompleted}
              isOpen={item.position === activeStep}
              setIsOpen={() => setActiveStep(item.position)}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};
export default Accordion;
