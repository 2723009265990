import environment from './environment';
import { User } from 'oidc-client-ts';

const retries = 4;
let tries = 0;

const getAuth = async () => {
  const storageString =
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? 'oidc.user:https://fed.tdc.dk:Panda'
      : 'oidc.user:https://fedpoc.pp.tdc.dk:Panda';

  const oidcStorage = sessionStorage.getItem(storageString);
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage)?.access_token;
};

// const getAuth = async () => {
//   if (sessionStorage && sessionStorage.getItem('auth')) {
//     return sessionStorage.getItem('auth');
//   }
//
//   //If this is dev, just fetch the token directly - I have no clue what tod o in prod yet?
//   const config = {
//     method: 'POST',
//     headers: new Headers({
//       'Content-Type': 'application/json',
//       Accept: 'application/json',
//     }),
//     body: JSON.stringify({ password: 'aowjwet3459!A' }),
//   };
//
//   try {
//     const response = await fetch(
//       'https://wpapi-proxy-test-wpapi-test.public.lqd.dk/token/v1/get-wonder-test-token/',
//       config
//     );
//
//     response.json().then((data) => {
//       sessionStorage.setItem('auth', data.access_token);
//       return data.access_token;
//     });
//   } catch (error) {
//     return error;
//   }
// };

const client = async (url, method, data, dontUseEnv) => {
  const token = await getAuth();

  if (!token) {
    alert('Noget gik galt, prøv igen');
  }

  let config = {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      'Accept-Encoding': 'gzip, deflate, br',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
    }),
  };

  if (method !== 'GET' && data) {
    config.body = JSON.stringify(data);
  }

  try {
    tries++;
    const fullUrl = dontUseEnv ? url : environment.api.host + url;
    const response = await fetch(fullUrl, config);

    if (response.status === 401) {
      //If 401, we'll try to re auth and all again
      //sessionStorage.removeItem('auth');

      if (tries < retries) {
        setTimeout(() => {
          client(url, method, data);
        }, 500);
      } else if (tries === retries) {
      } else {
        alert('Kald til serveren mislykkedes, prøv venligst igen senere.');
      }
    }

    return response;
  } catch (error) {
    //Assume auth token is invalid for now? (stupid) And run again (LOOPIE)
    sessionStorage.removeItem('auth');
    //client(url, method);
  }
};

const getSubscriptions = () => {
  return client('product/v2/get-mobile-voice-with-category', 'GET');
};

const creditEval = (data) => {
  return client('creditevaluation/v1/get-credit-evaluation/', 'POST', data);
};

const createBasket = (data) => {
  return client('basket/dialog/v1.1/subscription/create-basket', 'PUT', data);
};

const setBasketSubscription = (basketId, productCode) => {
  return client(`basket/dialog/v1.1/${basketId}/product/${productCode}`, 'PUT');
};

const replaceBasketSubscription = (basketId, productCode, number) => {
  return client(
    `/dialog/v1.1/${basketId}/product/${productCode}/subscription/${number}`,
    'PUT'
  );
};

const setContactInfo = (basketId, data) => {
  return client(
    `basket/dialog/v1.1/${basketId}/order-contact-info/`,
    'PUT',
    data
  );
};

const getProductBasket = (basketId) => {
  return client(`basket/v1/get-product-basket/${basketId}/`, 'GET');
};

const getNumbers = (pattern) => {
  return client(
    `number/v1/get-numbers/6${!!pattern ? '?CtnPattern=' + pattern : ''}`,
    'GET'
  );
};

const reserveNumber = (number) => {
  return client(`number/v1/reserve-number/${number}`, 'POST');
};

const addNumberAndICC = (basketId, subIndex, data) => {
  return client(
    `basket/dialog/v1.1/${basketId}/subscription/${subIndex}/mobileNumberNew`,
    'PUT',
    data
  );
};

const getDialog = (basketId) => {
  return client(`basket/dialog/v1.1/getDialog/${basketId}`, 'GET');
};

const finalizeDialog = (basketId) => {
  return client(`basket/dialog/v1.1/${basketId}/finalize`, 'PUT');
};

const placeOrder = (basketId, dryRun) => {
  return client(`order/v1/create-order/${basketId}?dryrun=${dryRun}`, 'POST');
};

const findOrder = (data) => {
  return client(`order/v1/findOrders`, 'POST', data);
};

const findOrderAttachement = (orderId) => {
  return client(`order/v1/orderAttachments/${orderId}`, 'GET');
};

const getCustomer = (number) => {
  return client(`customerlookup/v3/get-customer/${number}`, 'GET');
};

const swapSubscriptions = (
  productBaksetId,
  productCode,
  msisdn,
  shouldCascade
) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/product/${productCode}/subscription/${msisdn}?cascadeChildSubscriptions=${shouldCascade}`,
    'PUT'
  );
};

const setNumberListing = (productBaksetId, subscriptionIndex, listing) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/subscription/${subscriptionIndex}/listed?listedCode=${listing}`,
    'PUT'
  );
};

const portNumber = (productBaksetId, subscriptionIndex, data) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/subscription/${subscriptionIndex}/mobileNumberPorting`,
    'PUT',
    data
  );
};

const setSaldoMax = (productBaksetId, subscriptionIndex, data) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/subscription/${subscriptionIndex}/saldomax`,
    'PUT',
    data
  );
};

const setDevice = (productBaksetId, subscriptionIndex, data) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/subscription/${subscriptionIndex}/mobileDevice`,
    'PUT',
    data
  );
};

const removeDevice = (productBaksetId, subscriptionIndex) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/subscription/${subscriptionIndex}/mobileDevice`,
    'DELETE'
  );
};

const initNemid = () => {
  return client(`nemid/v1/initiate-nem-id`, 'GET');
};

const validateNemid = (data) => {
  return client(`nemid/v1/validate-nemid-login`, 'POST', data);
};

const validateCpr = (data) => {
  return client(`nemid/v1/validate-cpr-number`, 'POST', data);
};

const getPOA = (basketId, productBaksetId, productId) => {
  return client(
    `basket/dialog/v1.1/${basketId}/basket/${productBaksetId}/product/${productId}/poa-contract`,
    'GET'
  );
};

const setPOA = (basketId, data) => {
  return client(`basket/dialog/v1.1/${basketId}/attachment`, 'PUT', data);
};

const loginSalesPerson = (data) => {
  return client(`login/v1/salesman-login-salesmancode`, 'POST', data);
};

const getSalesPersons = (dealercode) => {
  return client(`login/v1/get-salesman?dealercode=${dealercode}`, 'GET');
};

const removeAddon = (productBaksetId, path) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/deselectAddon?addonPath=${path}`,
    'PUT'
  );
};

const addAddon = (productBaksetId, path, data) => {
  return client(
    `basket/dialog/v1.1/${productBaksetId}/addon/parameter?addonPath=${path}`,
    'PUT',
    data
  );
};

const addSalesPerson = (data) => {
  return client(`login/v1/add-salesman`, 'POST', data);
};

const deleteSalesPerson = (dealercode, salesmancode) => {
  return client(
    `login/v1/delete-salesman/${dealercode}/${salesmancode}`,
    'POST'
  );
};

const updateSalesKey = (dealercode, salesmancode, data) => {
  return client(
    `login/v1/update-key/${dealercode}/${salesmancode}`,
    'POST',
    data
  );
};

const updateSalesPerson = (dealercode, salesmancode, data) => {
  return client(
    `login/v1/update-salesman/${dealercode}/${salesmancode}`,
    'POST',
    data
  );
};

export {
  getSubscriptions,
  creditEval,
  createBasket,
  setBasketSubscription,
  replaceBasketSubscription,
  setContactInfo,
  getProductBasket,
  getNumbers,
  addNumberAndICC,
  reserveNumber,
  getDialog,
  finalizeDialog,
  placeOrder,
  getCustomer,
  swapSubscriptions,
  setNumberListing,
  portNumber,
  setSaldoMax,
  setDevice,
  removeDevice,
  initNemid,
  validateNemid,
  validateCpr,
  getPOA,
  setPOA,
  loginSalesPerson,
  removeAddon,
  getSalesPersons,
  findOrder,
  findOrderAttachement,
  addSalesPerson,
  deleteSalesPerson,
  updateSalesKey,
  updateSalesPerson,
  addAddon,
};
