import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import Header from './modules/Header';
import LoginIllustration from './assets/login-illustration.svg';

import Login from './containers/Login';
import Flow from './containers/Flow';
import OrderLookup from './containers/OrderLookup';
import UserManagement from './containers/UserManagement';
import Button from './modules/Button/';

import Spinner from './modules/Spinner';
import './styles/App.scss';

function App() {
  const auth = useAuth();

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      if (
        alert(
          'Du er ved at blive logget ud, grundet inaktivitet - fortsæt for at forblive logget ind.'
        )
      ) {
        auth.signinSilent();
      }
    });
  }, [auth.events, auth.signinSilent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <main className="App">
      <Router>
        {(auth.isLoading && (
          <section className="load-screen">
            <h1>Du omstilles til butiks-login</h1>
            <Spinner />
          </section>
        )) ||
          (auth.isAuthenticated && (
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/mobil">
                <Flow />
              </Route>
              <Route exact path="/mobil/order">
                <OrderLookup />
              </Route>
              <Route exact path="/mobil/users">
                <UserManagement />
              </Route>
            </Switch>
          )) || (
            <>
              <section className="load-screen">
                <Header />
                <h1>Du skal logge ind som butik</h1>
                <Button
                  display="inline"
                  value="Gå til butiks login"
                  onClick={() => auth.signinRedirect()}
                />
              </section>
              <img
                src={LoginIllustration}
                alt="Girl with computer"
                className="login__illustration"
              />
            </>
          )}
      </Router>
    </main>
  );
}

export default App;
