import React, { useState, useEffect } from 'react';
import { getSubscriptions } from '../../api/endpoints';
import './style.scss';
import Header from '../../modules/Header';
import Subscriptions from '../../modules/Subscriptions';
import Accordion from '../../modules/Accordion';
import CustomerStep from '../../modules/CustomerStep';
import CreditEvaluation from '../../modules/CreditEvaluation';
import CustomerLookup from '../../modules/CustomerLookup';
import Contact from '../../modules/Contact';
import Order from '../../modules/Order';
import Approval from '../../modules/Approval';
import Button from '../../modules/Button/';

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
  EXISTING_EXTRA_UPGRADE: 'EXISTING_EXTRA_UPGRADE',
};

const tries = 3;
let tryNum = 0;

const Flow = () => {
  const [isCompleted, setIsCompleted] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [originalSubscription, setOriginalSubscription] = useState(null);
  const [originalNumber, setOriginalNumber] = useState(null);
  const [extraSubscriptions, setExtraSubscriptions] = useState(null);
  const [existingType, setExistingType] = useState();
  const [existingExtras, setExistingExtras] = useState(0);

  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [email, setContactEmail] = useState('');
  const [basket, setBasket] = useState({});
  const [productBasket, setProductBasket] = useState({});
  const [subscriptions, setSubscriptions] = useState({});
  const [isStandAlone, setIsStandAlone] = useState(false);

  const setStepCompleted = (step) => {
    if (step > isCompleted) {
      setIsCompleted(step);
    }

    setActiveStep(step);
  };

  useEffect(() => {
    const fetchSubs = async () => {
      tryNum++;
      const response = await getSubscriptions();

      try {
        const products = await response.json();
        setSubscriptions(products);
      } catch (error) {
        //It seems to almost always be that it didnt ready the auth yet
        if (tryNum < tries) {
          setTimeout(() => {
            fetchSubs();
          }, 200);
        }
      }
    };

    fetchSubs();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header />
      <article className="flow col-10 container">
        <Accordion
          isCompleted={isCompleted}
          setIsCompleted={setIsCompleted}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          items={[
            {
              title: 'Er dette en eksisterende YouSee kunde?',
              content: (
                <CustomerStep
                  setIsExistingCustomer={setIsExistingCustomer}
                  setIsCompleted={setStepCompleted}
                />
              ),
              isCompleted: isCompleted > 0,
              position: 0,
            },
            isExistingCustomer && {
              title: 'Søg efter kunde',
              content: (
                <CustomerLookup
                  subscriptions={subscriptions}
                  setOriginalNumber={setOriginalNumber}
                  setOriginalSubscription={setOriginalSubscription}
                  setSelectedSubscription={setSelectedSubscription}
                  setIsCompleted={setStepCompleted}
                  setExistingType={setExistingType}
                  setExistingExtras={setExistingExtras}
                  setIsStandAlone={setIsStandAlone}
                />
              ),
              isCompleted: isCompleted > 1,
              position: 1,
            },
            existingType !== types.EXISTING_KEEP && {
              title: 'Vælg Abonnement',
              content: (
                <Subscriptions
                  extraSubscriptions={extraSubscriptions}
                  setExtraSubscriptions={setExtraSubscriptions}
                  isExistingCustomer={isExistingCustomer}
                  originalSubscription={originalSubscription}
                  setSelectedSubscription={setSelectedSubscription}
                  selectedSubscription={selectedSubscription}
                  existingType={existingType}
                  existingExtras={existingExtras}
                  setIsStandAlone={setIsStandAlone}
                  setIsCompleted={() => setStepCompleted(3)}
                />
              ),
              isCompleted: isCompleted > 2,
              position: 2,
            },
            {
              title: 'Kreditvurdering',
              content: (
                <CreditEvaluation
                  setIsCompleted={() => setStepCompleted(4)}
                  originalNumber={originalNumber}
                  setCustomerInfo={setCustomerInfo}
                  selectedSub={selectedSubscription}
                  extraSubscriptions={extraSubscriptions}
                  setBasket={setBasket}
                  setProductBasket={setProductBasket}
                  originalSubscription={originalSubscription}
                  isExistingCustomer={isExistingCustomer}
                  existingType={existingType}
                />
              ),
              isCompleted: isCompleted > 3,
              position: 3,
            },
            {
              title: 'Kontaktoplysninger',
              content: (
                <Contact
                  setIsCompleted={() => setStepCompleted(5)}
                  customerInfo={customerInfo}
                  basket={basket}
                  setBasket={setBasket}
                  setContactEmail={setContactEmail}
                />
              ),
              isCompleted: isCompleted > 4,
              position: 4,
            },
            {
              title: 'Bestilling',
              content: (
                <Order
                  setIsCompleted={() => setStepCompleted(6)}
                  email={email}
                  productBasket={productBasket}
                  basket={basket}
                  setProductBasket={setProductBasket}
                  existingType={existingType}
                  isStandAlone={isStandAlone}
                />
              ),
              isCompleted: isCompleted > 5,
              position: 5,
            },
            {
              title: 'Godkend ordre',
              content: (
                <Approval
                  setIsCompleted={() => setStepCompleted(7)}
                  productBasket={productBasket}
                  basket={basket}
                />
              ),
              isCompleted: isCompleted > 6,
              position: 6,
            },
          ]}
        />
        {isCompleted > 0 && isCompleted < 6 && (
          <Button
            value="Annuller"
            isLink
            onClick={() => window.location.reload()}
            className="flow__cancel"
          />
        )}
      </article>
    </>
  );
};

export default Flow;
