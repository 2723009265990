import React, { useState } from 'react';
import './style.scss';
import TextInput from '../../modules/TextInput/';
import Button from '../../modules/Button/';
import { getCustomer } from '../../api/endpoints';

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
  EXISTING_EXTRA_UPGRADE: 'EXISTING_EXTRA_UPGRADE',
  EXISTING_NEW_SUBSCRIPTION: 'EXISTING_NEW_SUBSCRIPTION',
};

const CustomerLookup = (props) => {
  const {
    setIsCompleted,
    setOriginalSubscription,
    setOriginalNumber,
    setExistingType,
    setSelectedSubscription,
    setExistingExtras,
    subscriptions,
  } = props;

  const [number, setNumber] = useState('61640084');
  const [error, setError] = useState('');
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCustomerInfo = async () => {
    setCustomer(null);
    if (!number || number.length !== 8) {
      setError('Et telefonnummer er 8 cifre');
    } else {
      setIsLoading(true);
      const response = await getCustomer(number);

      try {
        if (response.status === 404) {
          const { mesage } = await response.json();
          setError(mesage);
          setIsLoading(false);
        } else if (response.status === 200) {
          const data = await response.json();
          setCustomer(data);
          setIsLoading(false);
        } else {
          setError('Noget gik galt, prøv igen!');
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const chooseSub = (sub, type, subNumber) => {
    setOriginalSubscription(sub);
    setOriginalNumber(subNumber);
    setExistingType(type);

    const numberOfExtras = customer?.subscriptions?.filter((sub) =>
      sub.product.productCode.includes('MOBIL')
    );

    setExistingExtras(numberOfExtras.length);

    if (type === types.EXISTING_CHANGE) {
      setIsCompleted(2);
    } else if (type === types.EXISTING_ADD_EXTRA) {
      setSelectedSubscription(sub);
      setIsCompleted(2);
    } else if (type === types.EXISTING_EXTRA_UPGRADE) {
      setSelectedSubscription(sub);
      setIsCompleted(2);
    } else if (type === types.EXISTING_KEEP) {
      setIsCompleted(3);
    }
  };

  const goForNew = () => {
    const numberOfExtras = customer?.subscriptions?.filter((sub) =>
      sub.product.productCode.includes('MOBIL')
    );

    setExistingType('EXISTING_NEW_SUBSCRIPTION');
    setExistingExtras(numberOfExtras.length);
    setIsCompleted(2);
  };

  const atlSubs = subscriptions && subscriptions.filter((sub) => sub.atl);
  const canBuyMore =
    customer?.subscriptions?.filter((sub) =>
      sub.product.productCode.includes('MOBIL')
    ).length < 6;
  const canBuyAtAll = customer?.allowedToSell;

  const hasNoAtl = !customer?.subscriptions?.find((subscription) =>
    atlSubs.find(
      (atlSubscription) =>
        atlSubscription.topLevelProduct.productCode ===
        subscription.product.productCode
    )
  );

  const hasAnyInProgress = !!customer?.subscriptions.find(
    (sub) => sub?.product?.startDate === '0001-01-01'
  );

  return (
    <div className="customer-lookup">
      <TextInput
        className="contact__input"
        error={error}
        onChange={(e, number) => {
          setNumber(number);
          setError('');
        }}
        name="tel"
        placeholder="Indtast kundens telefonnummer"
        label="Telefonnummer:"
      />
      <Button
        value="Søg"
        display="inline"
        onClick={() => getCustomerInfo()}
        loading={isLoading}
      />
      {customer && (
        <section className="customer-lookup__details">
          <h2 className="customer-lookup__details__title">Kunde fundet:</h2>
          {canBuyAtAll ? (
            <table className="customer-lookup__details__subscriptions">
              <thead className="customer-lookup__details__subscriptions__header">
                <tr>
                  <td>Nummer</td>
                  <td>Navn</td>
                  <td>Abonnement</td>
                  <td>Status</td>
                  <td>Pris</td>
                </tr>
              </thead>
              <tbody className="customer-lookup__details__subscriptions__body">
                {customer.subscriptions.map((sub, index) => {
                  const key = `customer-sub-${index}`;
                  const isExtra = sub.product.productName.includes('Ekstra');
                  const canChangeAtAll =
                    sub.product.productCode.includes('MOBIL') &&
                    !hasAnyInProgress;

                  const isAtl = atlSubs.find(
                    (atlSub) =>
                      atlSub.topLevelProduct.productCode ===
                      sub.product.productCode
                  );

                  const isInProgress = sub?.product?.startDate === '0001-01-01';
                  const bindingEnd = sub?.product?.bindingDate;

                  return (
                    <>
                      <tr key={key}>
                        <td className={isInProgress ? 'in-progress' : ''}>
                          {sub.msisdn}
                        </td>
                        <td>
                          {customer.name.firstName} {customer.name.lastName}{' '}
                        </td>
                        <td>{sub.product.productName}</td>
                        <td>{isInProgress ? 'Igangværende' : 'Aktiv'}</td>
                        <td>{sub.product.chargeAmount},-</td>
                        {canChangeAtAll && (
                          <>
                            {!isExtra && (
                              <td>
                                <Button
                                  value="Ændre"
                                  color="dark"
                                  size="small"
                                  onClick={() =>
                                    chooseSub(
                                      sub.product.productCode,
                                      types.EXISTING_CHANGE,
                                      sub.msisdn
                                    )
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <Button
                                value="Gentegn"
                                color="dark"
                                size="small"
                                onClick={() =>
                                  chooseSub(
                                    sub.product.productCode,
                                    types.EXISTING_KEEP,
                                    sub.msisdn
                                  )
                                }
                              />
                            </td>
                            {isAtl && (
                              <>
                                {canBuyMore && (
                                  <td>
                                    <Button
                                      value="Tilføj ekstra"
                                      color="dark"
                                      size="small"
                                      onClick={() =>
                                        chooseSub(
                                          sub.product.productCode,
                                          types.EXISTING_ADD_EXTRA,
                                          sub.msisdn
                                        )
                                      }
                                    />
                                  </td>
                                )}
                              </>
                            )}
                            {!isAtl && isExtra && (
                              <td>
                                <Button
                                  value="Ændre til standalone"
                                  color="dark"
                                  size="small"
                                  onClick={() =>
                                    chooseSub(
                                      sub.product.productCode,
                                      types.EXISTING_EXTRA_UPGRADE,
                                      sub.msisdn
                                    )
                                  }
                                />
                              </td>
                            )}
                          </>
                        )}
                        {bindingEnd && (
                          <div className="customer-lookup__details__subscriptions__binding">
                            <span className="customer-lookup__details__subscriptions__binding__exlamation">
                              !
                            </span>
                            I binding indtil: {bindingEnd}
                          </div>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <h3>
              Kunden kan ikke ændre sit mobil abonnement uden at ændre de andre
              produkter. Kontakt derfor forhandler support.
            </h3>
          )}

          {hasAnyInProgress && (
            <h3>
              Kunden kan ikke ændre sine abonnementer, når der er igangværende
              ordrer.
            </h3>
          )}
        </section>
      )}

      {customer && hasNoAtl && !hasAnyInProgress && (
        <section>
          <h2>
            Da kunden ikke har et basis abonnement, kan kunden vælge nyt
            herunder:
          </h2>
          <Button value="Køb helt nyt abonnement" onClick={() => goForNew()} />
        </section>
      )}
    </div>
  );
};

export default CustomerLookup;
