import React, { useState } from 'react';
import Button from '../../modules/Button/';
import TextInput from '../../modules/TextInput/';
import './style.scss';
import { setContactInfo } from '../../api/endpoints';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^[ÆØÅæøåa-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[ÆØÅæøåa-zA-Z0-9](?:[ÆØÅæøåa-zA-Z0-9-]{0,61}[ÆØÅæøåa-zA-Z0-9])?(?:\.[ÆØÅæøåa-zA-Z0-9](?:[ÆØÅæøåa-zA-Z0-9-]{0,61}[ÆØÅæøåa-zA-Z0-9])?)*$/
    );
};

const Contact = (props) => {
  const { setIsCompleted, customerInfo, basket, setBasket, setContactEmail } =
    props;

  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [numberError, setNumberError] = useState('');
  const [mailError, setMailError] = useState('');
  const [genericError, setGenericError] = useState('');

  const onContinue = async () => {
    setIsLoading(true);
    if (!email || !number || number.length !== 8 || !validateEmail(email)) {
      if (!email || !validateEmail(email)) {
        setMailError('Indtast en valid email (test@yousee.dk)');
      }

      if (!number || number.length !== 8) {
        setNumberError('Indtast 8 cifret telefonnummer');
      }

      setIsLoading(false);
    } else {
      const response = await setContactInfo(basket.id, {
        email,
        phoneNo: number,
      });

      try {
        const { basket } = await response.json();

        if (response.status !== 200) {
          setGenericError(basket.message);
          setIsLoading(false);
        } else {
          setBasket(basket);
          setContactEmail(email);
          setIsLoading(false);
          setIsCompleted();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!customerInfo || !customerInfo.customerName) {
    return <p>Loading...</p>;
  }

  return (
    <div className="contact">
      <h2 className="contact__title">Kontaktoplysninger</h2>
      <p className="contact__address">
        {customerInfo.customerName.firstName}{' '}
        {customerInfo.customerName.lastName}
        <br />
        {customerInfo.address.street} {customerInfo.address.houseNumber},{' '}
        {customerInfo.address.floor}. {customerInfo.address.floorSide}.<br />
        {customerInfo.address.postalCode} {customerInfo.address.city}
        <br />
      </p>
      <TextInput
        className="contact__input"
        value={email}
        onChange={(e, email) => {
          setEmail(email);
          setMailError('');
        }}
        name="email"
        placeholder="Indtast kundens e-mail adresse"
        label="E-mail:"
        error={mailError}
      />
      <p>
        Tast den mailadresse, hvor kunden ønsker at modtage regninger,
        ordrebekræftelser og vigtigt nyt om sit abonnement – så længe kunden
        beholder abonnementet.
      </p>
      <TextInput
        className="contact__input"
        value={number}
        onChange={(e, number) => {
          setNumber(number);
          setNumberError('');
        }}
        name="tel"
        numberOnly
        maxLength="8"
        placeholder="Indtast kundens telefonnummer"
        label="Telefonnummer:"
        error={numberError}
      />
      <p>
        Vi bruger nummeret, hvis vi har brug for at kontakte kunden om
        bestillingen. Vi gemmer også nummeret, så vi let kan hjælpe kunden, hvis
        kunden glemmer sit YouSee Login
      </p>
      <Button
        className="contact__button"
        display="inline"
        value="Fortsæt"
        onClick={onContinue}
        loading={isLoading}
      />
      {genericError && <p>{genericError}</p>}
    </div>
  );
};

export default Contact;
