import React, { useState, useRef } from 'react';
import Button from '../../modules/Button/';
import './style.scss';
import {
  placeOrder,
  getPOA,
  setPOA,
  getProductBasket,
} from '../../api/endpoints';
import { Document, Page, pdfjs } from 'react-pdf';
import CanvasDraw from 'react-canvas-draw';
import { PDFDocument } from 'pdf-lib';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Order = (props) => {
  const { basket, productBasket } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const [genericError, setGenericError] = useState('');
  const [pdfFile, setPdfFile] = useState();
  const [active, setActive] = useState();

  const canvasRef = useRef(null);

  const submitOrder = async () => {
    const doneArray = isSaved.filter((pdf) => pdf);

    const amountOfMove = subscriptions.filter((subscription) => {
      try {
        const addons = Object.entries(subscription.addons);

        const numberSettings = addons.filter((addon) => {
          return addon[1].groupClass === 'NUMBER_TYPE';
        });

        const numberType = numberSettings.filter((addon) => {
          return addon[1].selected;
        });

        if (numberType[0][1].name === 'Nummerportering') {
          return true;
        }
      } catch (error) {
        return false;
      }

      return false;
    });

    if (doneArray.length !== amountOfMove.length) {
      setGenericError('Underskriv venligst alle PDFer');
    } else {
      setIsLoading(true);
      const response = await placeOrder(basket.id, false);

      try {
        const orderResponse = await response.json();
        if (orderResponse.result === 'SUCCESS') {
          setIsDone(true);
          setOrderID(orderResponse.orderId);
        } else {
          setGenericError(orderResponse.message);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const subscriptions =
    productBasket?.customerSubscriptions?.subscriptions || null;
  const changedSubscriptions = subscriptions.filter((sub) => !sub.locked);

  let priceRecurring = 0;
  let priceCreation = 0;
  let leastPrice = 0;

  const [isSaved, setIsSaved] = useState(
    new Array(changedSubscriptions.length)
  );

  try {
    changedSubscriptions.forEach((subscription) => {
      priceRecurring += subscription.priceSpecification.unitPricePrMonth;

      const addons = Object.entries(subscription.addons);

      const dataSharingSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'DATADEL_01';
      });

      if (dataSharingSettings && dataSharingSettings.length) {
        if (dataSharingSettings[0][1]?.selected) {
          priceRecurring += dataSharingSettings[0][1]?.prices.filter(
            (price) => price.priceEventType === 'MONTHLY'
          )[0].priceWithVat;
        }
      }
    });
  } catch {
    priceRecurring = 0;
  }

  try {
    changedSubscriptions.forEach((subscription) => {
      priceCreation += subscription.priceSpecification.priceline.find(
        (line) => line.type === 'CREATION'
      ).actualPrice;

      const addons = Object.entries(subscription.addons);

      const dataSharingSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'DATADEL_01';
      });

      if (dataSharingSettings && dataSharingSettings.length) {
        if (dataSharingSettings[0][1]?.selected) {
          priceCreation += dataSharingSettings[0][1]?.prices.filter(
            (price) => price.priceEventType === 'ADD'
          )[0].priceWithVat;
        }
      }
    });
  } catch {
    priceCreation += 0;
  }

  try {
    changedSubscriptions.forEach((subscription) => {
      leastPrice += subscription.priceSpecification.minimumPrice;
      const addons = Object.entries(subscription.addons);

      const dataSharingSettings = addons.filter((addon) => {
        return addon[1].addonClass === 'DATADEL_01';
      });

      if (dataSharingSettings && dataSharingSettings.length) {
        if (dataSharingSettings[0][1]?.selected) {
          leastPrice += dataSharingSettings[0][1]?.prices.filter(
            (price) => price.priceEventType === 'MONTHLY'
          )[0].priceWithVat;
        }
      }
    });
  } catch {
    leastPrice += 0;
  }

  leastPrice += priceCreation;

  if (isDone) {
    return (
      <div className="approval">
        <section className="approval__details">
          <h1>Tak for ordren!</h1>
          <div>
            <h2>Camunda Order ID:</h2>
            <p>{orderID}</p>
          </div>
        </section>
      </div>
    );
  }

  const startLegalOwner = async (subscriptionIndex) => {
    const response = await getProductBasket(basket.productBasketIds[0]);

    try {
      const { productBasket } = await response.json();

      console.log(productBasket);
      console.log(subscriptionIndex);

      const poaResponse = await getPOA(
        basket.id,
        basket.productBasketIds[0],
        productBasket.products[subscriptionIndex].id
      );

      try {
        const { contentBase64 } = await poaResponse.json();
        setPdfFile(contentBase64);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveSignature = async (signature, index) => {
    const pdfDoc = await PDFDocument.load(pdfFile);
    const pngImage = await pdfDoc.embedPng(signature);
    const pngDims = pngImage.scale(1);
    const pages = pdfDoc.getPages();

    pages[0].drawImage(pngImage, {
      x: pages[0].getWidth() / 2 - pngDims.width / 2,
      y: 400,
      width: pngDims.width,
      height: pngDims.height,
    });

    const pdfBytes = await pdfDoc.saveAsBase64();

    const response = await setPOA(basket.id, {
      contentBase64: pdfBytes,
      description:
        'A power-of-attorney authorizing us to import a mobile number from another operator',
      filename: 'poa.pdf',
    });

    try {
      const body = await response.json();

      if (body.result === 'SUCCESS') {
        const localSaved = [...isSaved];
        localSaved[index] = true;
        setIsSaved(localSaved);
        setPdfFile(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="approval">
      <section className="approval__details">
        <h2 className="approval__details__title">Godkend</h2>
        <div className="approval__details__basket">
          <h3 className="approval__details__basket__title">Mobilabonnement</h3>
          <ul className="approval__details__basket__subscriptions">
            {changedSubscriptions &&
              changedSubscriptions.map((subscription, index) => {
                let selectedNumber = '0';
                let numberTypeText = 'Behold dit nummer - ';
                let isMoveNumber = false;
                const pdfIsDone = isSaved[index];
                const addons = Object.entries(subscription.addons);
                let dataSharingPrice = false;

                const dataSharingSettings = addons.filter((addon) => {
                  return addon[1].addonClass === 'DATADEL_01';
                });

                if (dataSharingSettings && dataSharingSettings.length) {
                  if (dataSharingSettings[0][1]?.selected) {
                    dataSharingPrice = dataSharingSettings[0][1]?.prices.filter(
                      (price) => price.priceEventType === 'MONTHLY'
                    )[0].priceWithVat;
                  }
                }

                try {
                  selectedNumber = Object.keys(
                    productBasket.customerSubscriptions.subscriptionIndexMap
                  ).find(
                    (key) =>
                      productBasket.customerSubscriptions.subscriptionIndexMap[
                        key
                      ] === subscription.subscriptionIndex
                  );
                } catch (error) {
                  //Do nothing
                }

                try {
                  const addons = Object.entries(subscription.addons);

                  const numberSettings = addons.filter((addon) => {
                    return addon[1].groupClass === 'NUMBER_TYPE';
                  });

                  const numberType = numberSettings.filter((addon) => {
                    return addon[1].selected;
                  });

                  if (numberType[0][1].name === 'Mobilnummer') {
                    numberTypeText = 'Nyt nummer - ';
                  }

                  if (numberType[0][1].name === 'Nummerportering') {
                    numberTypeText = 'Flyt nummer - ';
                    isMoveNumber = true;
                  }
                } catch (error) {
                  //Do nothing
                }

                const key = `approval-subscription-${index}`;
                return (
                  <>
                    <li
                      key={key}
                      className="approval__details__basket__subscriptions__subscription"
                    >
                      {pdfFile && active === index && (
                        <div className="approval__details__basket__pdf-wrap">
                          <section className="approval__details__basket__pdf">
                            <Document
                              file={`data:application/pdf;base64,${pdfFile}`}
                            >
                              <Page pageNumber={1} />
                            </Document>
                            {!pdfIsDone && (
                              <CanvasDraw
                                ref={canvasRef}
                                className="approval__details__basket__pdf__signature"
                                brushRadius={2}
                                brushColor="#000"
                                lazyRadius={0}
                                hideInterface
                                hideGridX
                                hideGridY
                              />
                            )}
                            {!pdfIsDone && (
                              <Button
                                value="Clear"
                                isLink
                                display="inline"
                                onClick={() => {
                                  canvasRef.current.eraseAll();
                                }}
                              />
                            )}
                            <Button
                              value={pdfIsDone ? 'Luk' : 'Gem pdf'}
                              onClick={() => {
                                if (pdfIsDone) {
                                  setPdfFile(null);
                                } else {
                                  saveSignature(
                                    canvasRef.current.getDataURL(),
                                    index
                                  );
                                }
                              }}
                            />
                          </section>
                        </div>
                      )}
                      <div>
                        <h4 className="approval__details__basket__subscriptions__subscription__title">
                          {subscription.productName} <span>(pr./md.)</span>
                          {selectedNumber && selectedNumber !== '0' && (
                            <p className="approval__details__basket__subscriptions__subscription__title__description">
                              {numberTypeText}
                              {selectedNumber.match(/.{1,2}/g).join(' ')}
                              <br />
                              {(isMoveNumber && !pdfIsDone && (
                                <Button
                                  value="underskriv pdf"
                                  display="inline"
                                  onClick={() => {
                                    setActive(index);
                                    startLegalOwner(index);
                                  }}
                                />
                              )) ||
                                (isMoveNumber && (
                                  <Button
                                    value="pdf underskrevet"
                                    isLink
                                    display="inline"
                                  />
                                ))}
                            </p>
                          )}
                        </h4>
                      </div>
                      <p className="approval__details__basket__subscriptions__subscription__price">
                        {subscription.priceSpecification.unitPricePrMonth},-
                      </p>
                    </li>
                    {typeof dataSharingPrice === 'number' && (
                      <li
                        key={`${key}-extra`}
                        className="approval__details__basket__subscriptions__subscription approval__details__basket__subscriptions__subscription--extra"
                      >
                        <p>Datadelingskort</p>
                        <p className="approval__details__basket__subscriptions__subscription__price">
                          {dataSharingPrice},-
                        </p>
                      </li>
                    )}
                  </>
                );
              })}
          </ul>
          <h3 className="approval__details__basket__header approval__details__basket__header--blue">
            <span>Betal pr./md.</span> <span>{priceRecurring},-</span>
          </h3>
          <h3 className="approval__details__basket__header">
            <span>Oprettelse</span> <span>{priceCreation},-</span>
          </h3>
          <h3 className="approval__details__basket__header approval__details__basket__header--blue">
            <span>Engangsbeløb i alt</span> <span>{priceCreation},-</span>
          </h3>
          <p className="approval__details__basket__least">
            Mindstepris i 6 måneder {leastPrice},-
          </p>
          <p className="approval__details__basket__legal">
            Der er 6 måneders binding og 1 måneds opsigele, ved køb af telefon.
          </p>
        </div>
        <Button
          className="approval__details__button"
          value="Godkend"
          display="inline"
          onClick={() => submitOrder()}
          loading={isLoading}
        />
        {genericError && <p>{genericError}</p>}
      </section>
    </div>
  );
};

export default Order;
