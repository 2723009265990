import React from 'react';
import { Collapse } from 'react-collapse';
import classNames from 'classnames';

const Item = (props) => {
  const { isOpen, setIsOpen } = props;

  const headerClasses = classNames('accordion__item__header', {
    'accordion__item__header--open': isOpen,
  });

  return (
    <section className="accordion__item">
      <header className={headerClasses} onClick={() => setIsOpen()}>
        <h1 className="accordion__item__header__title">{props.title}</h1>
      </header>
      <Collapse isOpened={isOpen}>{props.content}</Collapse>
    </section>
  );
};
export default Item;
