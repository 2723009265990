import Button from '../../modules/Button';
import fiveg from '../../assets/5g.svg';
import fourg from '../../assets/4g.svg';
import checked from '../../assets/checked.svg';
import notchecked from './no-check.svg';

const Subscription = (props) => {
  const {
    subscription,
    currentSub,
    currentSelected,
    onContinue,
    buttonText,
    number,
  } = props;
  const priceRecurring = subscription.priceSpecification.actualPricePrMonth;
  const hasFiveG = subscription.includedAddons?.find((addon) =>
    addon.productName.includes('5G')
  );
  const hasYouseeMusic = subscription.includedAddons?.find((addon) =>
    addon.productName.includes('YouSee Musik')
  );
  const hasFreeDK = !subscription.productName.includes('Time');
  const hasForeignSpeak =
    subscription.productFeatures?.includedHoursRoamingType === 'WORLD';
  const dataDK = subscription.productFeatures?.includedDataLimitDenmark;
  const dataForeign = subscription.productFeatures?.includedDataLimitRoaming;

  const name =
    subscription.productName === 'Mobil 1 Time / 3 GB'
      ? 'Børneabonnement'
      : subscription.productName;

  return (
    <tr>
      <td className="subscriptions__table__name">
        {currentSub && (
          <div className="subscriptions__table__label">Nuværende</div>
        )}
        {name} {number}
      </td>
      <td>{dataDK ? `${dataDK} GB` : 'Fri'}</td>
      <td>{dataForeign} GB</td>
      <td>
        {hasForeignSpeak ? (
          <img alt="checkbox" src={checked} />
        ) : (
          <img alt="checkbox" src={notchecked} />
        )}
      </td>
      <td>
        {hasFreeDK ? (
          <img alt="checkbox" src={checked} />
        ) : (
          <img alt="checkbox" src={notchecked} />
        )}
      </td>
      <td colSpan="2">
        {hasYouseeMusic ? (
          <img alt="checkbox" src={checked} />
        ) : (
          <img alt="checkbox" src={notchecked} />
        )}
      </td>
      <td colSpan="2">
        {hasFiveG ? (
          <img alt="checkbox" src={fiveg} />
        ) : (
          <img alt="checkbox" src={fourg} />
        )}
      </td>
      <td className="subscriptions__table__right">{priceRecurring},-</td>
      <td className="subscriptions__table__right">
        <Button
          display="inline"
          color={currentSelected ? 'dark' : 'primary'}
          size="small"
          value={buttonText}
          onClick={() => onContinue()}
        />
      </td>
    </tr>
  );
};

export default Subscription;
