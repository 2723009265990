import React from 'react';
import './styles.scss';
import '@youseedk/dna/css/elements/ys-spinner.css';

const Spinner = () => (
  <div className="container spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100px"
      height="100px"
      viewBox="0 0 50 50"
      className="ys-spinner"
    >
      <circle
        className="ys-spinner__circle"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
  </div>
);

export default Spinner;
