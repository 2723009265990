import './style.scss';
import Button from '../../modules/Button/';

function CustomerCheck(props) {
  const { setIsCompleted, setIsExistingCustomer } = props;

  return (
    <div className="customer-check">
      <Button
        color="light-bordered"
        value="Nej"
        onClick={() => {
          setIsExistingCustomer(false);
          setIsCompleted(2);
        }}
      />
      <Button
        variant="cta"
        value="Ja"
        onClick={() => {
          setIsExistingCustomer(true);
          setIsCompleted(1);
        }}
      />
    </div>
  );
}

export default CustomerCheck;
