import React, { useState, useEffect } from 'react';
import {
  addSalesPerson,
  getSalesPersons,
  deleteSalesPerson,
  updateSalesKey,
  updateSalesPerson,
} from '../../api/endpoints';
import { useAuth } from 'react-oidc-context';

import Header from '../../modules/Header';
import TextInput from '../../modules/TextInput/';
import Button from '../../modules/Button/';
import { Collapse } from 'react-collapse';
import './style.scss';

const UserManagement = () => {
  const [salesmanname, setSalesmanName] = useState('');
  const [salesmanaddress, setSalesmanAddress] = useState('');
  const [salesmancity, setSalesmanCity] = useState('');
  const [salesmanemailid, setSalesmanEmailid] = useState('');
  const [salesmanmobilno, setSalesmanMobilno] = useState('');
  const [salesmanpostno, setSalesmanPostno] = useState('');
  const [salesmancode, setSalesmanCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');
  const [active, setActive] = useState('');
  const [salesPeople, setSalesPeople] = useState([]);
  const [salesPerson, setSalesPerson] = useState();

  const [oldKey, setOldKey] = useState('');
  const [newKey, setNewKey] = useState('');

  const auth = useAuth();

  const dealerCode = auth?.user?.profile?.storenumber;

  const createSalesPerson = async () => {
    setError('');
    setLoading(true);

    const data = {
      dealerCode,
      salesmanname,
      salesmanaddress,
      salesmancity,
      salesmanemailid,
      salesmanmobilno,
      salesmanpostno,
      salesmancode,
    };

    const response = await addSalesPerson(data);

    try {
      const body = await response.json();

      if (body?.mesage?.includes('Error')) {
        setError(body.mesage);
      }

      if (body?.status?.resultType === 'SUCCESS') {
        alert('Oprettet');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePerson = async () => {
    const response = await deleteSalesPerson(dealerCode, salesPerson);

    try {
      const body = await response.json();

      if (body?.mesage?.includes('Error')) {
        setError(body.mesage);
      }

      if (body?.status?.resultType === 'SUCCESS') {
        alert('Slettet');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateKey = async () => {
    const response = await updateSalesKey(dealerCode, salesPerson, {
      oldKey,
      newKey,
    });

    try {
      const body = await response.json();

      if (body?.mesage?.includes('Error')) {
        setError(body.mesage);
      }

      if (body?.status?.resultType === 'SUCCESS') {
        alert('Opdateret');
      }

      if (body?.status?.resultType === 'INPUT_ERROR') {
        setError(body?.status?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateInfo = async () => {
    setLoading(true);

    const data = {
      salesmanname,
      salesmanaddress,
      salesmancity,
      salesmanemailid,
      salesmanmobilno,
      salesmanpostno,
    };

    const response = await updateSalesPerson(dealerCode, salesPerson, data);

    try {
      const body = await response.json();
      console.log(body);

      if (body?.mesage?.includes('Error')) {
        setError(body.mesage);
      }

      if (body?.status?.resultType === 'SUCCESS') {
        alert('Opdateret');
      }

      if (body?.status?.resultType === 'INPUT_ERROR') {
        setError(body?.status?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setUpdateSalesPerson = (salesPerson) => {
    setSalesPerson(salesPerson);

    const internalPerson = salesPeople.find(
      (person) => person.salesmancode === salesPerson
    );

    internalPerson.salesmanname
      ? setSalesmanName(internalPerson.salesmanname)
      : setSalesmanName('');
    internalPerson.salesmanaddress
      ? setSalesmanAddress(internalPerson.salesmanaddress)
      : setSalesmanAddress('');
    internalPerson.salesmancity
      ? setSalesmanCity(internalPerson.salesmancity)
      : setSalesmanCity('');
    internalPerson.salesmanemailid
      ? setSalesmanEmailid(internalPerson.salesmanemailid)
      : setSalesmanEmailid('');
    internalPerson.salesmanmobilno
      ? setSalesmanMobilno(internalPerson.salesmanmobilno)
      : setSalesmanMobilno('');
    internalPerson.salesmanpostno
      ? setSalesmanPostno(internalPerson.salesmanpostno)
      : setSalesmanPostno('');
    internalPerson.salesmancode
      ? setSalesmanCode(internalPerson.salesmancode)
      : setSalesmanCode('');
  };

  useEffect(() => {
    const fetchSalesPeople = async () => {
      const dealerCode = auth?.user?.profile?.storenumber;

      const response = await getSalesPersons(dealerCode);

      if (response.status === 200) {
        try {
          const body = await response.json();

          let sortedSalesPeople = body.sort((a, b) => {
            if (a.salesmanname && b.salesmanname) {
              return a.salesmanname.localeCompare(b.salesmanname);
            } else {
              return a.salesmancode.localeCompare(b.salesmancode);
            }
          });

          setSalesPeople(sortedSalesPeople);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchSalesPeople();
  }, [auth]);

  return (
    <main className="user">
      <Header />
      <section className="user__section">
        <h1 onClick={() => setActive('new')}>Opret ny salgsperson</h1>
        <Collapse isOpened={active === 'new'}>
          <div className="row">
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanName(value)}
              placeholder="Navn"
              value={salesmanname}
              label="Navn"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanAddress(value)}
              placeholder="Adresse"
              value={salesmanaddress}
              label="Adresse"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanCity(value)}
              placeholder="By"
              value={salesmancity}
              label="By"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanPostno(value)}
              placeholder="Postnummer"
              value={salesmanpostno}
              label="Postnummer"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanEmailid(value)}
              placeholder="Email"
              value={salesmanemailid}
              label="Email"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanMobilno(value)}
              placeholder="Telefonnummer"
              value={salesmanmobilno}
              label="Telefonnummer"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setSalesmanCode(value)}
              placeholder="Kode"
              value={salesmancode}
              label="Kode"
            />
            <div className="col-12">
              <br />
            </div>
            <Button
              value="Opret salgsperson"
              onClick={createSalesPerson}
              loading={loading}
            />
          </div>
        </Collapse>
      </section>
      <section className="user__section">
        <h1 onClick={() => setActive('delete')}>Slet salgsperson</h1>
        <Collapse isOpened={active === 'delete'}>
          <div className="row">
            <div className="ys-select col-6 col-md-4 col-lg-3">
              <label htmlFor="select" className="ys-input-field__label">
                <span className="ys-select__label-text">Sælger</span>
                <span className="ys-select__wrapper">
                  <select
                    className="ys-select__control"
                    id="select"
                    value={salesPerson}
                    defaultValue="null"
                    onChange={(e) => setSalesPerson(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Vælg sælger
                    </option>
                    {salesPeople.map((person, index) => {
                      const key = `person-${index}`;

                      if (person.salesmanname) {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmanname}
                          </option>
                        );
                      } else {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmancode}
                          </option>
                        );
                      }
                    })}
                  </select>
                </span>
              </label>
            </div>
            <div className="col-12">
              <br />
            </div>
            <Button
              value="SLET salgsperson"
              onClick={deletePerson}
              loading={loading}
            />
          </div>
        </Collapse>
      </section>
      <section className="user__section">
        <h1 onClick={() => setActive('changeKey')}>Skift kode</h1>
        <Collapse isOpened={active === 'changeKey'}>
          <div className="row">
            <div className="ys-select col-6 col-md-4 col-lg-3">
              <label htmlFor="select" className="ys-input-field__label">
                <span className="ys-select__label-text">Sælger</span>
                <span className="ys-select__wrapper">
                  <select
                    className="ys-select__control"
                    id="select"
                    value={salesPerson}
                    defaultValue="null"
                    onChange={(e) => setSalesPerson(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Vælg sælger
                    </option>
                    {salesPeople.map((person, index) => {
                      const key = `person-${index}`;

                      if (person.salesmanname) {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmanname}
                          </option>
                        );
                      } else {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmancode}
                          </option>
                        );
                      }
                    })}
                  </select>
                </span>
              </label>
            </div>
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setOldKey(value)}
              placeholder="Gamle kode"
              value={oldKey}
              label="Gamle kode"
            />
            <TextInput
              className="order-lookup__input"
              containerClass="col-6 col-md-4 col-lg-3"
              onChange={(name, value) => setNewKey(value)}
              placeholder="Ny kode"
              value={newKey}
              label="Ny kode"
            />
            <div className="col-12">
              <br />
            </div>
            <Button value="Skift kode" onClick={updateKey} loading={loading} />
          </div>
        </Collapse>
      </section>
      <section className="user__section">
        <h1 onClick={() => setActive('updateUser')}>Opdater salgsperson</h1>
        <Collapse isOpened={active === 'updateUser'}>
          <div className="row">
            <div className="ys-select col-6 col-md-4 col-lg-3">
              <label htmlFor="select" className="ys-input-field__label">
                <span className="ys-select__label-text">Sælger</span>
                <span className="ys-select__wrapper">
                  <select
                    className="ys-select__control"
                    id="select"
                    value={salesPerson}
                    defaultValue="null"
                    onChange={(e) => setUpdateSalesPerson(e.target.value)}
                  >
                    <option value="" disabled selected>
                      Vælg sælger
                    </option>
                    {salesPeople.map((person, index) => {
                      const key = `person-${index}`;

                      if (person.salesmanname) {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmanname}
                          </option>
                        );
                      } else {
                        return (
                          <option key={key} value={person.salesmancode}>
                            {person.salesmancode}
                          </option>
                        );
                      }
                    })}
                  </select>
                </span>
              </label>
            </div>
            {salesPerson && (
              <>
                <div className="col-12">
                  <br />
                </div>
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanName(value)}
                  placeholder="Navn"
                  value={salesmanname}
                  label="Navn"
                />
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanAddress(value)}
                  placeholder="Adresse"
                  value={salesmanaddress}
                  label="Adresse"
                />
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanCity(value)}
                  placeholder="By"
                  value={salesmancity}
                  label="By"
                />
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanPostno(value)}
                  placeholder="Postnummer"
                  value={salesmanpostno}
                  label="Postnummer"
                />
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanEmailid(value)}
                  placeholder="Email"
                  value={salesmanemailid}
                  label="Email"
                />
                <TextInput
                  className="order-lookup__input"
                  containerClass="col-6 col-md-4 col-lg-3"
                  onChange={(name, value) => setSalesmanMobilno(value)}
                  placeholder="Telefonnummer"
                  value={salesmanmobilno}
                  label="Telefonnummer"
                />
              </>
            )}
            <div className="col-12">
              <br />
            </div>
            <Button value="Opdater" onClick={updateInfo} loading={loading} />
          </div>
        </Collapse>
      </section>
      {error && <p>{error}</p>}
    </main>
  );
};

export default UserManagement;
