import React, { useState, useEffect } from 'react';
import TextInput from '../../modules/TextInput/';
import Header from '../../modules/Header';
import Button from '../../modules/Button/';
import Spinner from '../../modules/Spinner';
import { useAuth } from 'react-oidc-context';
import MUIDataTable from 'mui-datatables';
import { createTheme } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider } from '@mui/material/styles';

import { Document, Page } from 'react-pdf';
import xlsx from 'json-as-xlsx';

import {
  findOrder,
  getSalesPersons,
  findOrderAttachement,
} from '../../api/endpoints';
import DatePicker, { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import './style.scss';

registerLocale('da', da);

const muiCache = createCache({
  key: 'order-table',
  prepend: true,
});

const OrderLookup = () => {
  const [orderId, setOrderId] = useState('');
  const [orders, setOrders] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [number, setNumber] = useState('');
  const [cpr, setCpr] = useState('');
  const [salesPerson, setSalesPerson] = useState('');
  const [salesPeople, setSalesPeople] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [error, setError] = useState('');

  const [fromDate, setFromDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  const [toDate, setToDate] = useState(
    new Date(new Date().setHours(23, 59, 59, 0))
  );

  const auth = useAuth();

  //Fetch all salesperons for this dealer

  const columns = [
    {
      label: 'Reference',
      name: 'reference',
      options: { sort: true, filter: false },
    },
    {
      label: 'Status',
      name: 'status',
      options: { sort: true, filter: false },
    },
    {
      label: 'Oprettet',
      name: 'orderDate',
      options: { sort: true, filter: false },
    },
    {
      label: 'Navn',
      name: 'name',
      options: { sort: true, filter: false },
    },
    {
      label: 'Mobilnummer',
      name: 'mobileNo',
      options: { sort: true, filter: false },
    },
    {
      label: 'Type',
      name: 'type',
      options: { sort: true, filter: false },
    },
    {
      label: 'Abonnement',
      name: 'sellableProduct',
      options: { sort: true, filter: false },
    },
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    filter: false,
    responsive: 'simple',
    selectableRows: 'none',
    onRowClick: (data) => {
      showOrder(data);
    },
    textLabels: {
      body: {
        noMatch: 'Ingen ordrer matcher søgningen',
        toolTip: 'Sortér',
        columnHeaderTooltip: (column) => `Sortér efter ${column.label}`,
      },
      pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        rowsPerPage: 'Rows per page:',
        displayRows: 'of',
      },
      toolbar: {
        search: 'Søg',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      viewColumns: {
        title: 'Vis kolonner',
        titleAria: 'Vis/skjul kolonner',
      },
    },
  };

  const tableData =
    orders &&
    orders.map((order) => {
      let localRow = order;

      if (order.firstName) {
        localRow.name = `${order.firstName} ${order.lastName}`;
      } else {
        localRow.name = order.companyName;
      }

      if (order.orderState === 'closed.completed') {
        localRow.status = 'Færdiggjort';
      }

      switch (order.orderState) {
        case 'closed.completed':
          localRow.status = 'Ordre er afsluttet';
          break;
        case 'closed.aborted':
          localRow.status = 'Ordre annulleret';
          break;
        case 'saved.order':
          localRow.status = 'Kladde';
          break;
        case 'open.running.in_progress':
          localRow.status = 'Ordre er igang';
          break;
        case 'open.not_running.not_started':
          localRow.status = 'Ordren er under behandling';
          break;
        case 'open.not_running.suspended':
        case 'open.not_running.failed':
          localRow.status = 'Ordren er fejlet';
          break;
        case 'open.not_running.cancelled':
          localRow.status = 'Ordren er afvist';
          break;
        default:
          break;
      }

      switch (order.orderType) {
        case 'NEW_CREATION':
          localRow.type = 'Nyoprettelse';
          break;
        case 'NUMBER_PORTING':
          localRow.type = 'Nummerportering';
          break;
        case 'EXTEND_COMMITMENT':
          localRow.type = 'Bibehold';
          break;
        case 'CONVERSION':
          localRow.type = 'Konvertering';
          break;
        case 'CHANGE_SIMCARD':
          localRow.type = 'Udskift SIM kort';
          break;
        case 'FACILITY_CHANGE':
          localRow.type = 'Facilitetsændring';
          break;
        case 'MULTI_ORDER':
          localRow.type = 'Multiordre';
          break;
        default:
          break;
      }

      localRow.orderDate = order?.orderDate?.split('T')[0];

      return localRow;
    });

  const downloadData = () => {
    //console.log(tableData);

    let accumulatedTypes = [];

    let accumulated = [];

    //Manipulate the data a bit for xls
    const localData = tableData.map((each) => {
      let local = each;
      local.ceID = each?.creditEvaluationInfo?.id;
      local.ceStatus = each?.creditEvaluationInfo?.status;

      if (local.type && !accumulatedTypes.includes(local.type)) {
        accumulatedTypes.push(local.type);
      }

      if (!local.type && !accumulatedTypes.includes('Uden type')) {
        accumulatedTypes.push('Uden type');
      }

      return local;
    });

    accumulatedTypes.forEach((type) => {
      if (type === 'Uden type') {
        accumulated.push({
          type,
          amount: localData.filter((data) => !data.type).length,
        });
      } else {
        accumulated.push({
          type,
          amount: localData.filter((data) => data.type === type).length,
        });
      }
    });

    accumulated.push({
      type: 'Totalt',
      amount: localData.length,
    });

    const xlsData = [
      {
        sheet: 'Ordre',
        columns: [
          { label: 'Reference', value: 'reference' },
          { label: 'Order ID', value: 'orderId' },
          { label: 'Dato', value: 'orderDate' },
          { label: 'Forhandler', value: 'dealerCode' },
          { label: 'Salgsperson', value: 'salesman' },
          { label: 'Ordre type', value: 'type' },
          { label: 'Mobilnummer', value: 'mobileNo' },
          { label: 'CE ID', value: 'ceID' },
          { label: 'CE Status', value: 'ceStatus' },
          { label: 'Navn', value: 'name' },
          { label: 'Abonnement', value: 'sellableProduct' },
        ],
        content: localData,
      },
      {
        sheet: 'Ordre akkumuleret',
        columns: [
          { label: 'Ordre type', value: 'type' },
          { label: 'Ordre antal', value: 'amount' },
        ],
        content: accumulated,
      },
    ];

    const xlsSettings = {
      fileName: 'Dealer Report',
    };

    xlsx(xlsData, xlsSettings);
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              boxShadow: 'none',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              cursor: 'pointer',
            },
          },
        },
      },
    });

  useEffect(() => {
    const fetchSalesPeople = async () => {
      const dealerCode = auth?.user?.profile?.storenumber;

      const response = await getSalesPersons(dealerCode);

      if (response.status === 200) {
        try {
          const body = await response.json();

          let sortedSalesPeople = body.sort((a, b) => {
            if (a.salesmanname && b.salesmanname) {
              return a.salesmanname.localeCompare(b.salesmanname);
            } else {
              return a.salesmancode.localeCompare(b.salesmancode);
            }
          });

          setSalesPeople(sortedSalesPeople);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchSalesPeople();
  }, [auth]);

  const lookupOrder = async () => {
    setError('');
    setLoading(true);
    const dealerCode = auth?.user?.profile?.storenumber;

    const orderInfo = {
      firstName,
      lastName,
      orderId,
      dealerCode,
      dateRange: {
        fromDate,
        toDate,
      },
      mobileNo: number,
      cprno: cpr,
      salesman: salesPerson,
    };

    const response = await findOrder(orderInfo);

    try {
      const body = await response.json();
      if (body && body.orders && body.orders.length) {
        setOrders(body.orders);
      } else if (body.statusMessage === 'No Orders found') {
        setError('Ingen ordre fundet');
        setOrders('');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showOrder = async (data) => {
    setShowPdf(true);
    const order = orders.find((order) => order.reference === data[0]);
    const response = await findOrderAttachement(order.orderId);

    const pdf = await response.json();

    if (pdf.remarks[0].attachment[0].base64BinaryContent) {
      setPdf(pdf.remarks[0].attachment[0].base64BinaryContent);
    }
  };

  return (
    <>
      <Header />
      <article className="order-lookup col-10 container">
        <h1>Søg ordre</h1>
        <div className="row order-lookup__search">
          <TextInput
            className="order-lookup__input"
            containerClass="col-6 col-md-4 col-lg-3"
            onChange={(name, value) => setFirstName(value)}
            placeholder="Fornavn"
            value={firstName}
            label="Fornavn"
          />
          <TextInput
            className="order-lookup__input"
            containerClass="col-6 col-md-4 col-lg-3"
            onChange={(name, value) => setLastName(value)}
            name="telefon"
            placeholder="Efternavn"
            value={lastName}
            label="Efternavn"
          />
          <TextInput
            className="order-lookup__input"
            containerClass="col-6 col-md-4 col-lg-3"
            onChange={(name, value) => setNumber(value)}
            name="telefon"
            placeholder="Telefonnummer"
            value={number}
            type="tel"
            maxLength="8"
            label="Telefonnummer"
            numberOnly={true}
          />
          <TextInput
            className="order-lookup__input"
            containerClass="col-6 col-md-4 col-lg-3"
            onChange={(name, value) => setCpr(value)}
            name="telefon"
            placeholder="CPR nummer"
            value={cpr}
            type="tel"
            maxLength="10"
            label="CPR nummer"
            numberOnly={true}
          />
          <TextInput
            className="order-lookup__input"
            containerClass="col-6 col-md-4 col-lg-3"
            onChange={(name, value) => setOrderId(value)}
            name="telefon"
            placeholder="Ordre ID"
            value={orderId}
            type="tel"
            maxLength="8"
            label="Ordre ID"
            numberOnly={true}
          />
          <div className="ys-select col-6 col-md-4 col-lg-3">
            <label htmlFor="select" className="ys-input-field__label">
              <span className="ys-select__label-text">Sælger</span>
              <span className="ys-select__wrapper">
                <select
                  className="ys-select__control"
                  id="select"
                  value={salesPerson}
                  defaultValue="null"
                  onChange={(e) => setSalesPerson(e.target.value)}
                >
                  <option value="">Alle sælgere</option>
                  {salesPeople.map((person, index) => {
                    const key = `person-${index}`;

                    if (person.salesmanname) {
                      return (
                        <option key={key} value={person.salesmancode}>
                          {person.salesmanname}
                        </option>
                      );
                    } else {
                      return (
                        <option key={key} value={person.salesmancode}>
                          {person.salesmancode}
                        </option>
                      );
                    }
                  })}
                </select>
              </span>
            </label>
          </div>
          <div className="ys-select col-6 col-md-4 col-lg-3">
            <label htmlFor="select" className="ys-input-field__label">
              <strong>Vælg fra-dato</strong>
              <DatePicker
                locale="da"
                selected={fromDate}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => setFromDate(date)}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div className="ys-select col-6 col-md-4 col-lg-3">
            <label htmlFor="select" className="ys-input-field__label">
              <strong>Vælg til-dato</strong>
              <DatePicker
                locale="da"
                selected={toDate}
                onChangeRaw={(e) => e.preventDefault()}
                onChange={(date) => setToDate(date)}
                maxDate={new Date(new Date().setHours(23, 59, 59, 0))}
                dateFormat="dd/MM/yyyy"
              />
            </label>
          </div>
          <div className="col-12">
            <br />
          </div>
          <div className="col-12">
            <Button value="Søg" onClick={lookupOrder} loading={loading} />
          </div>
        </div>
        {showPdf && (
          <div className="order-lookup__pdf">
            <div
              className="order-lookup__pdf__close"
              onClick={() => {
                setShowPdf(false);
                setPdf(null);
              }}
            />
            {(pdf && (
              <Document file={`data:application/pdf;base64,${pdf}`}>
                <Page pageNumber={1} />
              </Document>
            )) || <Spinner />}
          </div>
        )}
        {error && <h2 className="order-lookup__error">{error}</h2>}
        {orders && orders.length && (
          <div>
            {(loading && <Spinner />) || (
              <CacheProvider value={muiCache}>
                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    columns={columns}
                    data={tableData}
                    options={options}
                  />
                </ThemeProvider>
              </CacheProvider>
            )}
          </div>
        )}
        <div className="col-12">
          <br />
        </div>
        {orders && orders.length && (
          <Button
            value="Download til excel"
            onClick={downloadData}
            loading={loading}
          />
        )}
      </article>
    </>
  );
};

export default OrderLookup;
