import React, { useState, useEffect } from 'react';
import './style.scss';
import Button from '../../modules/Button/';
import TextInput from '../../modules/TextInput/';
import {
  creditEval,
  createBasket,
  setBasketSubscription,
  swapSubscriptions,
} from '../../api/endpoints';

const types = {
  EXISTING_CHANGE: 'EXISTING_CHANGE',
  EXISTING_KEEP: 'EXISTING_KEEP',
  EXISTING_ADD_EXTRA: 'EXISTING_ADD_EXTRA',
  EXISTING_EXTRA_UPGRADE: 'EXISTING_EXTRA_UPGRADE',
  EXISTING_NEW_SUBSCRIPTION: 'EXISTING_NEW_SUBSCRIPTION',
};

const CreditEvaluation = (props) => {
  const {
    setIsCompleted,
    selectedSub,
    extraSubscriptions,
    setCustomerInfo,
    setBasket,
    setProductBasket,
    isExistingCustomer,
    originalNumber,
    originalSubscription,
    existingType,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [cprNo, setCprNo] = useState();
  const [pid, setPid] = useState();
  const [pidType, setPidType] = useState();
  const [genericError, setGenericError] = useState();
  const [showSlow, setShowSlow] = useState(false);
  const [hasShownSecurity, setHasShownSecurity] = useState(false);

  const user = JSON.parse(sessionStorage.user);

  const fetchCE = async () => {
    setIsLoading(true);
    setShowSlow(false);
    setGenericError('');

    const timeout = setTimeout(() => {
      setShowSlow(true);
    }, 5000);

    if (!hasShownSecurity) {
      setShowSlow(false);
      setIsLoading(false);
      setGenericError('Du skal have set sundhedskortet for at kunne fortsætte');
      clearTimeout(timeout);
      return;
    }

    let type = 'NEW_CUSTOMER';

    if (isExistingCustomer) {
      type =
        existingType === types.EXISTING_KEEP
          ? 'EXTEND_SUBSCRIPTION'
          : 'NEW_SUBSCRIPTION';
    }

    const response = await creditEval({
      cprNo,
      loginId:
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'EGApi'
          : 'EGApi_Test',
      orderType: type,
      pid,
      pidType,
    });

    try {
      const body = await response.json();
      if (body.result !== 'SUCCESS') {
        setGenericError(
          'Der er desværre sket en intern fejl eller timeout, prøv venligst igen'
        );
        setIsLoading(false);
        clearTimeout(timeout);
        setShowSlow(false);
        clearTimeout(timeout);
      } else {
        setShowSlow(false);
        clearTimeout(timeout);
        setIsLoading(false);

        if (body.creditEvaluationStatus !== 'ACCEPTED') {
          setGenericError(
            'Kredivurderingen kan ikke gennemføres - ceID: ' +
              body.creditEvaluationId
          );
        } else {
          basket();
        }
      }
    } catch (error) {
      setGenericError(error);
      setIsLoading(false);
      clearTimeout(timeout);
      setShowSlow(false);
    }
  };

  const basket = async () => {
    const response = await createBasket({
      cprAuthInfo: {
        cprNo,
        pid,
        pidType,
      },
      salesChannelInfo: {
        salesChannelId: user.dealercode,
        salesChannelName: user.dealername,
        salesPerson: user.salesmancode,
        salesPersonEmail: user.salesmanemailid,
      },
    });

    try {
      const body = await response.json();

      if (body.result !== 'SUCCESS') {
        setGenericError(body.message);
        setIsLoading(false);
      } else {
        setBasket(body.basket);
        setCustomerInfo(body.basket.customerInfo);
        setSubscription(body.basket.productBasketIds[0]);
      }
    } catch (error) {
      setGenericError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // const initiate = async () => {
    //   const response = await initNemid();
    //
    //   try {
    //     const { challenge, launch_url, parameters } = await response.json();
    //     setNemidChallenge(challenge);
    //     setNemidUrl(launch_url);
    //     setNemidParam(parameters);
    //   } catch(error) {
    //     console.log(error);
    //   }
    // }
    //
    // initiate();
  }, []);

  const addExtraSubscription = async (basketId) => {
    for (let i = 0; i < extraSubscriptions.length; i++) {
      const extraResponse = await setBasketSubscription(
        basketId,
        extraSubscriptions[i]
      );

      try {
        const productBasket = await extraResponse.json();
        setProductBasket(productBasket);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const setSubscription = async (basketId) => {
    if (isExistingCustomer) {
      if (existingType === types.EXISTING_KEEP) {
        //if keeping the sub, just fetch the product basket and move along
        const response = await swapSubscriptions(
          basketId,
          originalSubscription,
          originalNumber,
          false
        );

        try {
          const body = await response.json();

          if (body.result !== 'SUCCESS') {
            setGenericError(body.message);
            setIsLoading(false);
          } else {
            setProductBasket(body);
            setIsLoading(false);
            setIsCompleted();
          }
        } catch (error) {
          setIsLoading(false);
          setGenericError(error);
        }

        setIsLoading(false);
        return;
      }

      if (existingType === types.EXISTING_ADD_EXTRA) {
        if (extraSubscriptions && extraSubscriptions.length) {
          await addExtraSubscription(basketId);
        }

        setIsLoading(false);
        setIsCompleted();
      } else if (existingType === types.EXISTING_NEW_SUBSCRIPTION) {
        const response = await setBasketSubscription(basketId, selectedSub);

        try {
          const body = await response.json();

          if (body.result !== 'SUCCESS') {
            setGenericError(body.message);
            setIsLoading(false);
          } else {
            if (extraSubscriptions && extraSubscriptions.length) {
              await addExtraSubscription(basketId);
            } else {
              setProductBasket(body);
            }

            setIsLoading(false);
            setIsCompleted();
          }
        } catch (error) {
          setIsLoading(false);
          setIsCompleted();
        }
      } else {
        const response = await swapSubscriptions(
          basketId,
          selectedSub,
          originalNumber,
          true
        );

        try {
          const body = await response.json();

          if (body.result !== 'SUCCESS') {
            setGenericError(body.message);
            setIsLoading(false);
          } else {
            if (extraSubscriptions && extraSubscriptions.length) {
              await addExtraSubscription(basketId);
            } else {
              setProductBasket(body);
            }

            setIsLoading(false);
            setIsCompleted();
          }
        } catch (error) {
          setIsLoading(false);
          setIsCompleted();
        }
      }
    } else {
      const response = await setBasketSubscription(basketId, selectedSub);

      try {
        const body = await response.json();

        if (body.result !== 'SUCCESS') {
          setGenericError(body.message);
          setIsLoading(false);
        } else {
          if (extraSubscriptions && extraSubscriptions.length) {
            await addExtraSubscription(basketId);
          } else {
            setProductBasket(body);
          }

          setIsLoading(false);
          setIsCompleted();
        }
      } catch (error) {
        setIsLoading(false);
        setIsCompleted();
      }
    }
  };

  // const handleReceiveMessage = async (e) => {
  //   try {
  //     const message = JSON.parse(e.data)
  //     const { command, content } = message
  //
  //     if (command === 'SendParameters') {
  //         const win = document.querySelector('#nemidFrame').contentWindow;
  //         const postMessage = {
  //           command: 'parameters',
  //           content: JSON.stringify(nemidParam),
  //         }
  //
  //         if (nemidParam) {
  //           win?.postMessage(JSON.stringify(postMessage), e.origin);
  //         }
  //     }
  //
  //     if (command === 'changeResponseAndSubmit') {
  //         const decoded = window.atob(content)
  //         if (decoded === 'CAN002' && process.env.REACT_APP_ENVIRONMENT.production) {
  //             //Skipping nemid in prod just reloads.
  //             document.querySelector('#nemIDFrame').src = document.querySelector('#nemIDFrame').src
  //         } else if (decoded === 'CAN002' && !process.env.REACT_APP_ENVIRONMENT.production) {
  //             //Skipping nemid outside of prod, allows to continue without nemid
  //             setSkipNemid(true);
  //         } else if (decoded.length > 50) {
  //             const response = await validateNemid({ xmldsig: content, challenge: nemidChallenge});
  //
  //             try {
  //               const { pid } = await response.json();
  //               setPid(pid);
  //               setXmldsig(content);
  //               setNemidCompleted(true);
  //             } catch(error) {
  //               console.log(error);
  //             }
  //         }
  //     }
  //   } catch(error) {
  //     console.log('error');
  //   }
  //
  // }

  // if (!nemidUrl || !nemidChallenge || !nemidParam) {
  //   return (
  //     <div>Loading...</div>
  //   );
  // }

  return (
    <div className="credit-evaluation">
      <section className="container">
        {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
          <p>
            Du kan evt bruge:
            <br />
            <strong>cprNo</strong>: "2804880391"
            <br />
            <strong>pas</strong>: "A123456789"
          </p>
        )}
        <TextInput
          name="cpr"
          value={cprNo}
          onChange={(e, cpr) => setCprNo(cpr)}
          type="tel"
          numberOnly={true}
          placeholder="Indtast CPR"
          label="CPR Nr:"
        />
        <div className="ys-select">
          <label htmlFor="select" className="ys-input-field__label">
            <span className="ys-select__label-text">ID Type</span>
            <span className="ys-select__wrapper">
              <select
                className="ys-select__control"
                id="select"
                value={pidType}
                defaultValue="0"
                onChange={(e) => setPidType(e.target.value)}
              >
                <option value="0" disabled>
                  Vælg ID type
                </option>
                <option
                  className="ys-select__option text-right"
                  value="PASSPORT"
                >
                  Pas
                </option>
                <option
                  className="ys-select__option text-right"
                  value="DRIVERS_LICENSE"
                >
                  Kørekort
                </option>
                <option
                  className="ys-select__option text-right"
                  value="MILITARY_ID"
                >
                  Militær ID
                </option>
                <option
                  className="ys-select__option text-right"
                  value="RESIDENCE_PERMIT"
                >
                  Opholdstilladelse (med billede)
                </option>
              </select>
            </span>
          </label>
        </div>
        <TextInput
          name="pid"
          onChange={(e, pid) => setPid(pid)}
          placeholder="Indtast ID nummer"
          label="ID Nummer"
        />
        {pidType === 'PASSPORT' && (
          <p>
            Pasnummer nummer udgør 9 cifre, og kan være præfikset A, B eller P
          </p>
        )}
        {pidType === 'DRIVERS_LICENSE' && <p>Kørekort udgør 8 cifre</p>}
        {pidType === 'MILITARY_ID' && <p>Militær ID udgør 1-16 cifre</p>}
        {pidType === 'RESIDENCE_PERMIT' && (
          <p>
            Opholdstilladelsen udgør 1 bogstav efterfulgt af 5-6 cifre, eller 2
            bogstaver efterfulgt at 8 cifre.
          </p>
        )}
        <div className="ys-checkbox">
          <input
            type="checkbox"
            name="contactOption"
            id="8"
            value="owner"
            checked={hasShownSecurity}
            onChange={() => setHasShownSecurity((prevState) => !prevState)}
            className="ys-checkbox__control ys-u-visuallyhidden"
          />
          <label htmlFor="8" className="ys-checkbox__label">
            <span>Du bekræfter hermed at have set kundens sundhedskort</span>
          </label>
        </div>
        <Button
          className="credit-evaluation__button"
          display="inline"
          onClick={() => fetchCE()}
          value="Fortsæt"
          loading={isLoading}
        />
        {genericError && <p>{genericError}</p>}
        {showSlow && (
          <p>
            Det kan tage op til 1 minut at gennemføre kredit tjek, vent venligst
          </p>
        )}
      </section>
    </div>
  );
};

export default CreditEvaluation;
